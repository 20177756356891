import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import loginImage from "../../assets/png/splash.png";
import finalogo from "../../assets/png/finallogo.png";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Password } from "primereact/password";
import { useDispatch } from "react-redux";
import { baseURL } from "../../Config";
import { loginService } from "../../services/LoginService";

const Login = () => {
    const [rememberPassword, setRememberPassword] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const handleForgotPassword = () => {
        history.push("/forgotpassword");
    };
    const handleCheckbox = (event) => {
        setRememberPassword(event.target.checked);
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("This field is required").email("Invalid email format"),
        password: Yup.string().required("This field is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: async (data) => {
            setLoadingBtn(true);

            const result = await dispatch(loginService(data, "/api/v1/auth/Login", false, true));
            if (result?.status === 200) {
                sessionStorage.clear("credentials");

                if (rememberPassword) {
                    const manipulatedResult = result;
                    const imagepath = result?.data?.profileImage;
                    manipulatedResult.data.profileImage = `${baseURL}/${imagepath}`;
                    localStorage.setItem("userDetails", JSON.stringify(manipulatedResult));
                    localStorage.setItem("rememberMe", "y");
                } else {
                    const manipulatedResult = result;
                    const imagepath = result?.data?.profileImage;
                    manipulatedResult.data.profileImage = `${baseURL}/${imagepath}`;
                    sessionStorage.setItem("userDetails", JSON.stringify(manipulatedResult));
                    localStorage.setItem("rememberMe", "n");
                }

                history.push("/");
            } else {
                sessionStorage.setItem("credentials", data?.email);
            }
            setLoadingBtn(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="loginBgContainer 2">
                <img src={loginImage} alt="img txt" className="loginBgImage" />

                <div className="left-section">
                    <form onSubmit={formik.handleSubmit} className="login-form">
                        <div className="text-center">
                            <img src={finalogo} style={{ width: "200px", height: "60px", objectFit: "contain" }} alt="img txt" />
                        </div>

                        <div className="login-div mt-6">
                            <div className="mb-3">
                                <label htmlFor="emai1" className="block font-medium mb-2">
                                    Email<span style={{ color: "red" }}>*</span>
                                </label>
                                <InputText keyfilter={/[^\s]/} placeholder="Enter your email" id="email" name="email" value={formik?.values?.email} onChange={formik.handleChange} maxLength={50} type="email" autoComplete="off" className="w-12 p-inputtext-login " />
                                {getFormErrorMessage("email")}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="block font-medium mb-2">
                                    Password<span style={{ color: "red" }}>*</span>
                                </label>
                                <Password
                                    keyfilter={/[^\s]/}
                                    placeholder="Enter your password"
                                    id="password"
                                    type="password"
                                    value={formik?.values?.password}
                                    maxLength={15}
                                    onChange={formik.handleChange}
                                    autoComplete="off"
                                    name="password"
                                    className="w-12 p-inputtext-login "
                                    toggleMask
                                    feedback={false}
                                />
                                {getFormErrorMessage("password")}
                            </div>
                            <div className="flex justify-content-between items-center">
                                <div className="form-check text-left flex items-center">
                                    <input type="checkbox" className="form-check-remember" id="rememberPassword" name="checkbox" checked={rememberPassword} onChange={handleCheckbox} />
                                    <label className="font-light text-sm ml-2 " htmlFor="rememberPassword">
                                        Remember me
                                    </label>
                                </div>

                                <div onClick={handleForgotPassword} style={{ color: "orange", cursor: "pointer", textAlign: "right" }} className="font-light text-sm">
                                    Forgot Password?
                                </div>
                            </div>

                            <div className="col-12 d-flex flex-row text-center mt-5">
                                <Button className="Login-Button" label="Login" type="submit" loading={loadingBtn} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
