import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import editIcon from "../../../../assets/png/edit.png";
import deleteIcon from "../../../../assets/png/delete.png";
import Modal from "../../../../components/modal/Modal";
import viewIcon from "../../../../assets/png/view.png";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { handleDeleteRequest } from "../../../../services/DeleteTemplate";
import Loading from "../../../../components/Loader";
import AddVariant from "./AddVariant";
import EditViewVariant from "./EditViewVariant";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { handlePutRequest } from "../../../../services/PutTemplate";
import { Dropdown } from "primereact/dropdown";
import NavigationComponent from "../../../../components/NavigationComponent";
import { baseURL } from "../../../../Config";
import Paginator from "../../../../components/Paginator";
import axios from "axios";
import { FileUpload } from "primereact/fileupload";
import { toast } from "react-toastify";

const ManageVariant = () => {
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allVariant, setAllVariant] = useState([]);
    const [VariantData, setVariantData] = useState([]);
    const [variantdataId, setVariantDataId] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentModal, setCurrentModal] = useState(null);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        variantName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        updatedStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [switchData, setSwitchData] = useState(null);
    const [switchModal, setSwitchModal] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [statusValue, setStatusValue] = useState("all");
    const [filteredVariants, setFilteredVariants] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [offset, setOffset] = useState(0); // offset state
    const [searchInput, setSearchInput] = useState('');

    //paginator
    const [recordCount, setRecordCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [quotationsCount, setQuotationsCount] = useState(0);
    const statusOptions = [
        { id: "all", name: "All" },
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" },
    ];

    const dispatch = useDispatch();

    const userIdStr = localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails");

    const userId = JSON.parse(userIdStr);
    const userRole = userId?.data?.role;

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const statusTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                <InputSwitch
                    className="edit-btn ml-1 mt-2"
                    checked={rowData.variantIsActive == "1"}
                    onChange={() => {
                        handleSwitchModalOpen();
                        setSwitchData(rowData);
                    }}
                />
            </div>
        );
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                {!userRole?.toLowerCase().includes("user") && (
                    <Button className="edit-btn ml-1 px-2" onClick={() => handleEditUsers(rowData)}>
                        <img src={editIcon} alt="Edit" />
                    </Button>
                )}
                <Button className="edit-btn ml-1 px-2" onClick={() => handleViewVariant(rowData)}>
                    <img src={viewIcon} alt="View" />
                </Button>
                {!userRole?.toLowerCase().includes("user") && (
                    <Button
                        className="edit-btn ml-1 px-2"
                        onClick={() => {
                            handleOpenModal();
                            setVariantData(rowData);
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" />
                    </Button>
                )}
            </div>
        );
    };
    const handleInputSwitch = async () => {
        setLoadingBtn(true);

        let obj = {
            isActive: switchData?.variantIsActive ? false : true,
        };
        const response = await dispatch(handlePutRequest(obj, `/api/v1/variant/update/${switchData?.variantId}`, false, true));

        if (response?.status === 200) {
            getVariantList();
        }
        setLoadingBtn(false);
        handleSwitchModalClose();
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleSwitchModalOpen = () => {
        setSwitchModal(true);
    };
    const handleSwitchModalClose = () => {
        setSwitchModal(false);
    };
    const onHide = () => {
        setDisplayDialog(false);
        setCurrentModal(null);
    };

    const handleDialog = () => {
        setCurrentModal("Add");
        setDisplayDialog(true);
    };

    const handleEditUsers = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditable(true);
        setVariantDataId(rowData.variantId);
    };

    const handleViewVariant = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditable(false);
        setVariantDataId(rowData.variantId);
    };

    const getVariantList = async () => {
        setLoading(true);
        const params = new URLSearchParams({
            limit: recordCount,
            offset: offset,
        });
        const response = await dispatch(handleGetRequest(`/api/v1/variant/getAll?${params}`, false));
        if (response) {
            setQuotationsCount(parseInt(response?.data?.totalCount));
            setAllVariant(response?.data?.variant || []);
            setTotalPageCount(Math.ceil(response?.data?.totalCount / recordCount)); // Update total page count
        }
        setLoading(false);
    };

    useEffect(() => {
        const calculatedOffset = (currentPage - 1) * recordCount;
        setOffset(calculatedOffset);
    }, [currentPage, recordCount]);

    useEffect(() => {
        getVariantList();
    }, [currentPage, recordCount, offset]);

    // Handle updates to offset when the currentPage changes
    // useEffect(() => {
    //     setOffset((currentPage - 1) * recordCount);
    // }, [currentPage, recordCount]);

    useEffect(() => {
        const updatedResponse = allVariant?.filter((item) => {
            if (statusValue === "active") return item?.variantIsActive;
            else if (statusValue === "inactive") return !item?.variantIsActive;
            else return item;
        });
        setFilteredVariants(updatedResponse);
    }, [statusValue, allVariant]);

    const handleDeleteModel = async () => {
        setLoadingBtn(true);

        const response = await dispatch(handleDeleteRequest(`/api/v1/variant/delete/${VariantData?.variantId}`, true, true));
        if (response?.status === 200) {
            getVariantList();
        }
        setLoadingBtn(false);
        handleCloseModal();
    };

    const optionTemplate = (option) => {
        return <div className={option.className}>{option.name}</div>;
    };
    const selectedItemTemplate = (option) => {
        if (option) {
            return <div className={option.className}>{option.name}</div>;
        }
        return <span>Select Status</span>;
    };
    const ImageTemplate = (rowData) => {
        let imageUrl = rowData?.variantImages?.[0]?.img_path;
        return (
            <React.Fragment>
                <img
                    crossorigin="anonymous"
                    src={`${baseURL}/${imageUrl}`}
                    style={{ borderRadius: "10px" }}
                    width="70px"
                    height="45px"
                    alt="Variant Image"
                    onError={(e) => {
                        e.target.onerror = null; // Prevents looping error if fallback also fails
                        e.target.src = `${baseURL}/images/default-variant.png`; // Fallback image
                    }}
                />
            </React.Fragment>
        );
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.variantCreatedDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const headerText = currentModal === "Add" ? "Add Variant" : isEditable === true ? "Edit Variant" : "View Variant";
    const header = <span className="title-border">{headerText}</span>;

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]); // Store the selected file in statess
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert("Please select a file first!");
            return;
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
        setIsUploading(true);

        try {
            const response = await axios.post(`${baseURL}/api/v1/variant/uploadInventoryData`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (response.status === 200) {
                setIsUploading(false);
                setSelectedFile(false);
                toast.success(response?.data?.message);
                await getVariantList()
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            toast.error(error.message);
        }
    };

    const handleSearch = async () => {

        setLoading(true);
        const response = await dispatch(
            handleGetRequest(`/api/v1/variant/getAll?filter=${encodeURIComponent(searchInput)}`, false)
        );
        if (response?.status == 200) {
            setFilteredVariants(response?.data?.variant)
        }
        setLoading(false);
    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog resizable={false} header={header} visible={displayDialog} blockScroll style={{ width: "50vw" }} maskClassName="zindex8888" onHide={() => onHide()} draggable={false}>
                        {currentModal === "Add" ? <AddVariant onHide={onHide} getVariantList={getVariantList} /> : currentModal === "EditView" && <EditViewVariant onHide={onHide} variantdataId={variantdataId} getVariantList={getVariantList} isEditable={isEditable} />}
                    </Dialog>
                    <div>
                        <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleDeleteModel} rowData={VariantData} loadingBtn={loadingBtn} type="delete" headerText="Are you sure you want to delete this Variant?" />
                    </div>
                    <div>
                        <Modal
                            isOpen={switchModal}
                            onClose={handleSwitchModalClose}
                            onConfirm={handleInputSwitch}
                            type="active/inactive"
                            loadingBtn={loadingBtn}
                            rowData={switchData}
                            isActive={switchData?.variantIsActive}
                            headerText={`Are you sure you want to ${switchData?.variantIsActive ? "Inactive" : "Active"} this Variant?`}
                        />
                    </div>

                    <NavigationComponent title={`Variants Management`} addNewHandleDialog={handleDialog} />

                    <div className="card-ml-1 dark-bg">

                        {!selectedFile ? (
                            <>
                                <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                                    <input type="file" id="fileInput" width={"50px"} accept=".xlsx" onChange={handleFileChange} style={{ display: "none" }} />
                                    <Button label="Upload XLS" className="Btn_Upload " icon="pi pi-plus-circle" iconPos="left" onClick={() => document.getElementById("fileInput").click()} />
                                </div>
                            </>
                        ) : isUploading ? (
                            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                                <Button label="Uploading..." className="Btn_Upload ml-2" icon="pi pi-spin pi-spinner" disabled />
                            </div>
                        ) : (

                            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                                <Button label="Submit" className="Btn_Upload ml-2" onClick={handleUpload} icon="pi pi-upload" iconPos="left" />
                            </div>
                        )}

                        <DataTable
                            header={
                                <div className="flex justify-content-end custom-alignment">
                                    {" "}
                                    <span className="mr-2 font-semibold resp-status text-base">Status:</span>
                                    <Dropdown
                                        className="mr-2"
                                        style={{ width: "215px" }}
                                        options={statusOptions}
                                        optionLabel="name"
                                        optionValue="id"
                                        value={statusValue}
                                        onChange={(e) => setStatusValue(e.target.value)}
                                        placeholder="Select Status"
                                        itemTemplate={optionTemplate}
                                        valueTemplate={selectedItemTemplate}
                                    />
                                    <div className="search-container">
                                        <InputText
                                            className="input-field"
                                            placeholder="Search via variant, model, category"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                        />
                                        <button className="search-button pi pi-search" onClick={handleSearch}></button>
                                    </div>
                                </div>
                            }
                            filters={filters}
                            globalFilterFields={["variantName", "variantModelName", "categoryName", "year", "variantTransmission", "variantPowerType"]}
                            responsive={true}
                            responsiveLayout="scroll"
                            stripedRows
                            value={filteredVariants}
                            emptyMessage="No records found"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        // paginator
                        // rows={10}
                        // rowsPerPageOptions={[10, 20, 50]}

                        // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        // currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        >
                            <Column body={ImageTemplate} header="Image" style={{ width: "150px" }} />
                            <Column field="variantName" header="Variant" style={{ width: "150px" }} />
                            <Column field="variantModelName" header="Model" style={{ width: "150px" }} />
                            <Column field="variantCategoryName" header="Category" style={{ width: "150px" }} />
                            <Column field="variantYear" header="Year" style={{ width: "150px" }} />
                            <Column field="variantTransmission" header="Transmission" style={{ width: "150px" }} />
                            <Column field="variantPowerType" header="Power Type" style={{ width: "150px" }} />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created On" style={{ width: "190px" }} />
                            {!userRole?.toLowerCase().includes("user") && <Column body={statusTemplate} header="Status" style={{ width: "90px" }} />}
                            <Column header="Action" body={actionTemplate} style={{ width: "150px" }} />
                        </DataTable>
                        <Modal />
                    </div>
                    <Paginator
                        totalPageCount={Math.ceil(quotationsCount / recordCount)}
                        currentPage={currentPage}
                        recordCount={recordCount}
                        setRecordCount={setRecordCount}
                        setCurrentPage={setCurrentPage}
                        setTotalPageCount={setTotalPageCount}
                        quotationsCount={quotationsCount}
                        setOffset={setOffset} // Pass setOffset here
                    />
                </>
            )}
        </>
    );
};

export default ManageVariant;
