import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { handlePutRequest } from "../../../services/PutTemplate";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../services/GetTemplate";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import Loading from "../../../components/Loader";

const EditViewInventory = ({ onHide, getInventoryList, inventoryId }) => {
    const dispatch = useDispatch();
    const [part, setPart] = useState([]);
    const [variant, setVariant] = useState([]);
    const [inventoryData, setInventoryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [currencies, setCurrencies] = useState([]);

    const validationSchema = Yup.object().shape({
        quantity: Yup.string()
            .required("This field is required")
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .matches(/^[0-9]+$/, "Quantity must contain only numeric values"),
        // cost: Yup.string().required("This field is required"),
    });

    const getInventoryById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/inventories/byId/${inventoryId}`, false));
        if (response?.status === 200) {
            setInventoryData(response?.data[0]);
            const keyData = response?.data[0];
            formik.setFieldValue("partId", keyData?.partId);
            formik.setFieldValue("variantId", keyData?.variantId);
            formik.setFieldValue("quantity", keyData?.quantity);
            formik.setFieldValue("id", keyData?.inventoryId);
            formik.setFieldValue("variantsCurrencyCode", keyData?.variantsCurrencyCode);
            formik.setFieldValue("partsCurrencyCode", keyData?.partsCurrencyCode);
            formik.setFieldValue("cost", keyData?.isPart ? keyData?.partCost : keyData?.variantCost);
        }
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
        getInventoryById();
    }, [inventoryId]);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            partId: "",
            variantId: "",
            quantity: "",
            cost: "",
            isPart: "",
            variantsCurrencyCode: "",
            partsCurrencyCode: "",
            id: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let partData = {
                partId: data?.partId ? data?.partId : undefined,
                quantity: parseInt(data?.quantity),
                id: data?.id,
                currencyCode: data?.partsCurrencyCode,
                isPart: true,
                cost: data?.cost,
            };
            let vehicleData = {
                variantId: inventoryData?.variantId,
                quantity: parseInt(data?.quantity),
                id: data?.id,
                currencyCode: data?.variantsCurrencyCode,
                isPart: false,
                cost: data?.cost,
            };
            if (inventoryData?.isPart) {
                const res = await dispatch(handlePutRequest(partData, `/api/v1/inventories/update`, false, true));
                if (res.status == 200) {
                    getInventoryList();
                    onHide();
                }
            } else {
                const res = await dispatch(handlePutRequest(vehicleData, `/api/v1/inventories/update`, false, true));
                if (res.status == 200) {
                    getInventoryList();
                    onHide();
                }
            }
            setLoadingBtn(false);
        },
    });

    const getParts = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/parts/getAll", false));
        if (response) {
            setPart(response?.data);
        }
    };

    const getVariant = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/variant/getAll", false));

        if (response) {
            setVariant(response?.data);
        }
    };
    const getCurrencies = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/currencies/getAll", false));
        if (response) {
            setCurrencies(response?.data);
        }
    };
    useEffect(() => {
        getParts();
        getVariant();
        getCurrencies();
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            {loading ? <Loading /> : <></>}
            <form onSubmit={formik.handleSubmit}>
                {inventoryData?.isPart ? (
                    <>
                        <div className="p-fluid formgrid grid pl-2 pr-2">
                            <div className="field col-12 md:col-6">
                                <label> Part Name </label>
                                <span className="Label__Required">*</span>
                                <Dropdown
                                    className={classNames({ "p-invalid": isFormFieldValid("partId") }, "Dropdown__Round")}
                                    id="partId"
                                    name="partId"
                                    options={part}
                                    optionValue="part_id"
                                    optionLabel="part_name"
                                    value={formik.values.partId}
                                    onChange={formik.handleChange}
                                    disabled
                                    placeholder="Select Part Name"
                                />
                                {getFormErrorMessage("partId")}
                            </div>
                            <div className="field col-12 md:col-6">
                                <label> Quantity </label>
                                <span className="Label__Required">*</span>
                                <InputText keyfilter={/^[0-9]*(\.[0-9]*)?$/} className={classNames({ "p-invalid": isFormFieldValid("quantity") }, "Input__Round")} id="quantity" name="quantity" maxLength={6} value={formik.values.quantity} onChange={formik.handleChange} placeholder="Enter Quantity" />
                                {getFormErrorMessage("quantity")}
                            </div>{" "}
                            <div className="flex field col-12 md:col-6">
                                <div className="field col-12 md:col-6 ">
                                    <label> Price </label>
                                    <span className="Label__Required"></span>
                                    <InputText
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        className={classNames({ "p-invalid": isFormFieldValid("cost") }, "Input__Round")}
                                        id="cost"
                                        maxLength={8}
                                        name="cost"
                                        type="text"
                                        value={formik.values.cost}
                                        onChange={formik.handleChange}
                                        placeholder="Enter Price"
                                        disabled
                                    />
                                    {getFormErrorMessage("cost")}
                                </div>{" "}
                                <div className="field col-12 md:col-6 ml-0">
                                    <label> Currency </label>
                                    <span className="Label__Required"></span>
                                    <Dropdown
                                        placeholder="Select currency"
                                        className={classNames({ "p-invalid": isFormFieldValid("partsCurrencyCode") }, "Dropdown__Round")}
                                        id="partsCurrencyCode"
                                        name="partsCurrencyCode"
                                        options={currencies}
                                        optionValue="code"
                                        optionLabel="code"
                                        value={formik.values.partsCurrencyCode}
                                        filter
                                        showClear={!!formik?.values?.partsCurrencyCode}
                                        filterBy="code"
                                        showFilterClear={true}
                                        resetFilterOnHide={true}
                                        onChange={(e) => {
                                            formik?.setFieldValue("partsCurrencyCode", e.target.value ? e.target.value : "");
                                        }}
                                        disabled
                                    />
                                    {getFormErrorMessage("partsCurrencyCode")}
                                </div>
                            </div>
                            <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                                <Button className="Save-Button ml-2" label="Update" type="submit" loading={loadingBtn} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="p-fluid formgrid grid pl-2 pr-2">
                            <div className="field col-12 md:col-6">
                                <label> Variant Name </label>
                                <span className="Label__Required">*</span>
                                <Dropdown
                                    className={classNames({ "p-invalid": isFormFieldValid("variantId") }, "Dropdown__Round")}
                                    id="variantId"
                                    name="variantId"
                                    options={variant}
                                    optionValue="variantId"
                                    optionLabel="variantName"
                                    value={formik?.values?.variantId}
                                    onChange={formik.handleChange}
                                    disabled
                                    placeholder="Select Variant "
                                />
                                {getFormErrorMessage("variantId")}
                            </div>
                            <div className="field col-12 md:col-6">
                                <label> Quantity </label>
                                <span className="Label__Required">*</span>
                                <InputText keyfilter={/^[0-9]*(\.[0-9]*)?$/} className={classNames({ "p-invalid": isFormFieldValid("quantity") }, "Input__Round")} maxLength={6} id="quantity" name="quantity" value={formik.values.quantity} onChange={formik.handleChange} placeholder="Enter Quantity" />
                                {getFormErrorMessage("quantity")}
                            </div>
                            <div className="flex field col-12 md:col-6">
                                <div className="field col-12 md:col-6 ">
                                    <label> Price </label>
                                    <span className="Label__Required"></span>
                                    <InputText
                                        keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                        className={classNames({ "p-invalid": isFormFieldValid("cost") }, "Input__Round")}
                                        id="cost"
                                        maxLength={8}
                                        name="cost"
                                        type="text"
                                        value={formik.values.cost}
                                        onChange={formik.handleChange}
                                        placeholder="Enter Price"
                                        disabled
                                    />
                                    {getFormErrorMessage("cost")}
                                </div>{" "}
                                <div className="field col-12 md:col-6 ml-0">
                                    <label> Currency </label>
                                    <span className="Label__Required"></span>
                                    <Dropdown
                                        placeholder="Select currency"
                                        className={classNames({ "p-invalid": isFormFieldValid("variantsCurrencyCode") }, "Dropdown__Round")}
                                        id="variantsCurrencyCode"
                                        name="variantsCurrencyCode"
                                        options={currencies}
                                        optionValue="code"
                                        optionLabel="code"
                                        value={formik.values.variantsCurrencyCode}
                                        filter
                                        showClear={!!formik?.values?.variantsCurrencyCode}
                                        filterBy="code"
                                        showFilterClear={true}
                                        resetFilterOnHide={true}
                                        onChange={(e) => {
                                            formik?.setFieldValue("variantsCurrencyCode", e.target.value ? e.target.value : "");
                                        }}
                                        disabled
                                    />
                                    {getFormErrorMessage("variantsCurrencyCode")}
                                </div>
                            </div>
                            <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                                <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                                <Button className="Save-Button ml-2" label="Update" type="submit" loading={loadingBtn} />
                            </div>
                        </div>
                    </>
                )}
            </form>
        </>
    );
};

export default EditViewInventory;
