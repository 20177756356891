import React from "react";
import "./Modal.css";
import loginImage from "../../assets/png/deletepopup.png";
import logoutImage from "../../assets/png/logout.png";
import activepng from "../../assets/png/activelogo.png";
import inactivepng from "../../assets/png/inactivelogo.png";
import { Button } from "primereact/button";
import crossicon from "../../assets/png/cancel.png";

const Modal = ({ isOpen, onClose, onConfirm, rowData, headerText, type, loadingBtn, isActive }) => {

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal">
                <img src={crossicon} className="crossicon" onClick={onClose} />
                <div className="modal-content">
                    <p className="heading">{headerText}</p>
                    <p>{rowData?.type}</p>
                    <div className="mainModalImage">
                        <img src={type === "delete" ? loginImage : type === "logout" ? logoutImage : isActive ? inactivepng : activepng} alt="Image Alt Text" className="imgMainLogo" />
                    </div>
                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" onClick={onClose} label="Close" />
                        <Button
                            className="Save-Button ml-2"
                            onClick={() => {
                                onConfirm();
                            }}
                            label={type === "delete" ? "Delete" : type === "logout" ? "Confirm" : isActive ? "Inactive" : "Active"}
                            loading={loadingBtn}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
