import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../../../services/PostTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import SecuredFileChange from "../../../../components/SecuredFileChange/SecuredFileChange";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { Dialog } from "primereact";

const AddCarModel = ({ onHide, getModelList }) => {
    const dispatch = useDispatch();
    const [allMaker, setAllMaker] = useState([]);
    const [allCategory, setAllCategory] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [modellogoLink, setModellogoLink] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [displayImageDialog, setDisplayImageDialog] = useState(false);

    const modelImageRef = useRef(null);

    const validationSchema = Yup.object().shape({
        model_name: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required")
            .matches(/^[a-zA-Z0-9\s]*$/, "Model Name must be alpha numeric"),
        makerId: Yup.string().required("This field is required"),
        categoryId: Yup.string().required("This field is required"),
        // model_images: Yup.string().required("This field is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            categoryId: "",
            makerId: "",
            model_name: "",
            model_images: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let obj = {
                makerId: data?.makerId,
                categoryId: data?.categoryId,
                model_name: data?.model_name,
                model_images: selectedFile ? selectedFile : "",
            };

            const response = await dispatch(handlePostRequest(obj, "/api/v1/models/create", false, true));
            if (response?.status == 200) {
                getModelList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });
    const previewImage = () => {
        setDisplayImageDialog(true);
    };
    const closeDisplayDialog = () => {
        setDisplayImageDialog(false);
    };
    const handleImageReset = () => {
        formik.setFieldValue("model_images", "");
        setSelectedFile(null);
        setModellogoLink("");
        modelImageRef.current.value = null; // Reset the file input value
    };
    const handleModelIcon = () => {
        modelImageRef.current.click();
    };

    function handleModelImageChange(event) {
        setSelectedFile(null);
        formik.setFieldValue("model_images", "");
        setSelectedFileError("");
        const selectedFile = event.target.files[0];
        const fileTypes = ["image"];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            setModellogoLink(URL.createObjectURL(selectedFile));
            formik.setFieldValue("model_images", newFile?.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFile(base64String);
            };
            reader.readAsDataURL(newFile);
        }
    }

    const getMakerList = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/makers/getAllP", false));
        if (response) {
            const filteredMakers = response?.data?.filter((item) => {
                return item?.isActive === true;
            });
            setAllMaker(filteredMakers);
        }
        // if (response) {
        //     setAllMaker(response?.data);
        // }
    };
    const getCategoryList = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/category/getAllP", false));
        if (response) {
            const filteredCategories = response?.data?.filter((item) => {
                return item?.isActive === true;
            });
            setAllCategory(filteredCategories);
        }
        // if (response) {
        //     setAllCategory(response?.data);
        // }
    };

    useEffect(() => {
        getMakerList();
        getCategoryList();
    }, []);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        
        <>
            <Dialog visible={displayImageDialog} blockScroll style={{ width: "40vw" }} maskClassName="zindex9999" onHide={() => closeDisplayDialog()} draggable={false}>
                <img src={selectedFile} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
            </Dialog>

            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Model Name </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Model Name" className={classNames({ "p-invalid": isFormFieldValid("model_name") }, "Input__Round")} id="model_name" name="model_name" maxLength={30} type="text" value={formik.values.model_name} onChange={formik.handleChange} />
                        {getFormErrorMessage("model_name")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Maker Name </label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            className={classNames({ "p-invalid": isFormFieldValid("makerId") }, "Dropdown__Round")}
                            id="makerId"
                            name="makerId"
                            options={allMaker}
                            optionValue="id"
                            optionLabel="name"
                            filter
                            showClear={!!formik?.values?.makerId}
                            filterBy="name"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("makerId", e.target.value ? e.target.value : "");
                            }}
                            value={formik.values.makerId}
                            placeholder="Select Maker"
                        />
                        {getFormErrorMessage("makerId")}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label> Category </label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            className={classNames({ "p-invalid": isFormFieldValid("categoryId") }, "Dropdown__Round")}
                            id="categoryId"
                            name="categoryId"
                            options={allCategory}
                            optionValue="id"
                            optionLabel="name"
                            filter
                            showClear={!!formik?.values?.categoryId}
                            filterBy="name"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("categoryId", e.target.value ? e.target.value : "");
                            }}
                            value={formik.values.categoryId}
                            placeholder="Select Category"
                        />
                        {getFormErrorMessage("categoryId")}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label> Upload Image </label>
                        <div className="file-input-container " onClick={handleModelIcon}>
                            <input type="file" accept="image/*" style={{ display: "none" }} ref={modelImageRef} onChange={handleModelImageChange} />
                            <input className={classNames({ "p-invalid": isFormFieldValid("model_images") }, "custom-file p-inputtext p-component Input__Round")} type="text" placeholder="Choose a file..." value={formik.values.model_images} readOnly />
                            <span className="file-upload-icon">
                                <i className="pi pi-paperclip" />
                            </span>
                        </div>

                        {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage("model_images")}
                        {formik?.values?.model_images && (
                            <div style={{ position: "relative", width: "180px", height: "180px", margin: "auto", marginTop: "15px" }}>
                                <i className="pi pi-times closingIcon" onClick={() => handleImageReset()} style={{ cursor: "pointer", position: "absolute", top: "0", right: "0" }} />
                                <img
                                    src={modellogoLink}
                                    alt="Model Image"
                                    style={{ maxWidth: "100%", maxHeight: "100%", width: "150px", height: "150px", objectFit: "contain" }}
                                    onClick={() => {
                                        previewImage();
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" loading={loadingBtn} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddCarModel;
