import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import editIcon from "../../../../assets/png/edit.png";
import deleteIcon from "../../../../assets/png/delete.png";
import viewIcon from "../../../../assets/png/view.png";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handleDeleteRequest } from "../../../../services/DeleteTemplate";
import Modal from "../../../../components/modal/Modal";
import { baseURL } from "../../../../Config";
import AddBanner from "./AddBanner";
import EditViewBanner from "./EditViewBanner";
import Loading from "../../../../components/Loader";
import { useHistory } from "react-router-dom";
import NavigationComponent from "../../../../components/NavigationComponent";

const ManageBanner = () => {
    const [displayDialog, setDisplayDialog] = useState(false);
    const [allBanner, setAllBanner] = useState([]);
    const [bannerdataId, setBannerDataId] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bannerData, serBannerData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentModal, setCurrentModal] = useState(null);
    const [isEditable, setIsEditAble] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const history = useHistory();

    const userIdStr = localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails");

    const userId = JSON.parse(userIdStr);
    const userRole = userId?.data?.role;

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const dispatch = useDispatch();

    const onHide = () => {
        setDisplayDialog(false);
        setCurrentModal(null);
    };

    const handleDialog = () => {
        setCurrentModal("Add");
        setDisplayDialog(true);
    };

    const handleEditBanner = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditAble(true);
        setBannerDataId(rowData.id);
    };
    const handleViewBanner = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditAble(false);
        setBannerDataId(rowData.id);
    };

    const primaryImage = (rowData) => {
        return (
            <React.Fragment>
                <img
                    crossorigin="anonymous"
                    src={baseURL + "/" + rowData?.primary}
                    width="80px"
                    height="60px"
                    alt="Banner Image"
                    style={{ borderRadius: "10px" }}
                    onError={(e) => {
                        e.target.onerror = null; // Prevents looping error if fallback also fails
                        e.target.src = `${baseURL}/images/default-primary-banner.jpeg`; // Fallback image
                    }}
                />
            </React.Fragment>
        );
    };
    const secondaryImage = (rowData) => {
        return (
            <React.Fragment>
                <img
                    crossorigin="anonymous"
                    src={baseURL + "/" + rowData?.secondary}
                    width="80px"
                    height="60px"
                    alt="Banner Image"
                    style={{ borderRadius: "10px" }}
                    onError={(e) => {
                        e.target.onerror = null; // Prevents looping error if fallback also fails
                        e.target.src = `${baseURL}/images/default-secondary-banner.jpeg`; // Fallback image
                    }}
                />
            </React.Fragment>
        );
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                {!userRole?.toLowerCase().includes("user") && (
                    <Button className="edit-btn ml-1 px-2" onClick={() => handleEditBanner(rowData)}>
                        <img src={editIcon} alt="Edit" />
                    </Button>
                )}
                <Button className="edit-btn ml-1 px-2" onClick={() => handleViewBanner(rowData)}>
                    <img src={viewIcon} alt="View" />
                </Button>
                {!userRole?.toLowerCase().includes("user") && (
                    <Button
                        className="edit-btn ml-1 px-2"
                        onClick={() => {
                            handleOpenModal();
                            serBannerData(rowData);
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" />
                    </Button>
                )}
            </div>
        );
    };

    const getBannerList = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest("/api/v1/banners/getAll", false));
        if (response) {
            setAllBanner(response?.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        getBannerList();
    }, []);

    const handleDeletebanner = async () => {
        setLoadingBtn(true);

        const response = await dispatch(handleDeleteRequest(`/api/v1/banners/delete/${bannerData?.id}`, true, true));
        if (response?.status === 200) {
            getBannerList();
        }
        setLoadingBtn(false);
        handleCloseModal();
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const headerText = currentModal === "Add" ? "Add Banner" : isEditable === true ? "Edit Banner" : "View Banner";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog resizable={false} header={header} visible={displayDialog} baseZIndex={1100} blockScroll style={{ width: "40vw" }} onHide={() => onHide()} draggable={false}>
                        {currentModal === "Add" ? <AddBanner onHide={onHide} getBannerList={getBannerList} /> : currentModal === "EditView" && <EditViewBanner onHide={onHide} bannerdataId={bannerdataId} getBannerList={getBannerList} isEditable={isEditable} />}
                    </Dialog>

                    <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleDeletebanner} rowData={bannerData} loadingBtn={loadingBtn} type="delete" headerText="Are you sure you want to delete this Banner?" />
                    <NavigationComponent title={`Banner Management`} addNewHandleDialog={handleDialog} isBackButtonLink={() => history.goBack()} />

                    <div className="card-ml-1 dark-bg">
                        <DataTable
                            header={
                                <>
                                    <div className="flex justify-content-between custom-alignment" style={{ display: "flex", alignItems: "center" }}></div>
                                </>
                            }
                            responsive={true}
                            globalFilterFields={["model"]}
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            value={allBanner}
                            emptyMessage="No records found"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        >
                            <Column field="typeId.type" header="Banner Type" style={{ width: "150px" }} />
                            <Column body={primaryImage} header="Primary Banner" style={{ width: "150px" }} />
                            <Column body={secondaryImage} header="Secondary Banner" style={{ width: "150px" }} />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created On" style={{ width: "150px" }} />
                            <Column body={actionTemplate} header="Action" style={{ width: "150px" }} />
                        </DataTable>
                    </div>
                </>
            )}
        </>
    );
};

export default ManageBanner;
