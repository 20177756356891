import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { handlePutRequest } from "../../../../services/PutTemplate";
import { Dropdown } from "primereact/dropdown";
import Loading from "../../../../components/Loader";
import SecuredFileChange from "../../../../components/SecuredFileChange/SecuredFileChange";
import { baseURL } from "../../../../Config";
import classNames from "classnames";
import { Dialog } from "primereact";

const EditViewCompany = ({ onHide, getMakerList, makerdataId, isEditable }) => {
    const dispatch = useDispatch();
    const [country, setCountry] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [companylogoLink, setCompanylogoLink] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [displayImageDialog, setDisplayImageDialog] = useState(false);

    const companyImageRef = useRef(null);

    const getMakerById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/makers/byId/${makerdataId}`, false));
        if (response?.status === 200) {
            const keyData = response?.data;
            formik.setFieldValue("name", keyData?.name);
            formik.setFieldValue("countryId", keyData?.countryId.id);
            formik.setFieldValue("logo", keyData?.logo);
            setCompanylogoLink(baseURL + "/" + keyData?.logo);
        }
        setLoading(false);
    };

    useEffect(() => {
        getMakerById();
    }, [makerdataId]);

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            ?.matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),
        countryId: Yup.string().required("This field is required"),
        logo: Yup.string().required("This field is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            countryId: "",
            logo: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);
            let newdata = {
                name: data?.name,
                countryId: parseInt(data?.countryId),
                logo: selectedFile ? selectedFile : "",
            };

            const response = await dispatch(handlePutRequest(newdata, `/api/v1/makers/update/${makerdataId}`, false, true));
            if (response?.status === 200) {
                getMakerList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const getCountry = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/country/getAll", false));
        if (response) {
            setCountry(response?.data);
        }
    };

    useEffect(() => {
        getCountry();
    }, []);

    const previewImage = () => {
        setDisplayImageDialog(true);
    };
    const closeDisplayDialog = () => {
        setDisplayImageDialog(false);
    };

    const handleImageReset = () => {
        formik.setFieldValue("logo", "");
        setSelectedFile(null);
        setCompanylogoLink("");
        companyImageRef.current.value = null; // Reset the file input value
    };
    const handleCompanyIcon = () => {
        companyImageRef.current.click();
    };

    function handleCompanyImageChange(event) {
        setSelectedFile(null);
        formik.setFieldValue("logo", "");
        setSelectedFileError("");
        const selectedFile = event.target.files[0];
        const fileTypes = ["image"];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            setCompanylogoLink(URL.createObjectURL(selectedFile));
            formik.setFieldValue("logo", newFile?.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFile(base64String);
            };
            reader.readAsDataURL(newFile);
        }
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <Dialog visible={displayImageDialog} blockScroll style={{ width: "40vw" }} maskClassName="zindex9999" onHide={() => closeDisplayDialog()} draggable={false}>
                <img src={companylogoLink} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
            </Dialog>
            {loading ? <Loading /> : <></>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Maker Name </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Maker Name" className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} id="name" name="name" type="text" maxLength={20} value={formik.values.name} onChange={formik.handleChange} disabled={!isEditable} />
                        {getFormErrorMessage("name")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Country </label>
                        <span className="Label__Required">*</span>

                        <Dropdown
                            placeholder="Select Country"
                            className={classNames({ "p-invalid": isFormFieldValid("countryId") }, "Dropdown__Round")}
                            disabled={!isEditable}
                            id="countryId"
                            name="countryId"
                            options={country}
                            optionValue="id"
                            optionLabel="name"
                            value={formik.values.countryId}
                            filter
                            showClear={!!formik?.values?.countryId}
                            filterBy="name"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("countryId", e.target.value ? e.target.value : "");
                            }}
                        />
                        {getFormErrorMessage("countryId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> {isEditable ? "Upload logo " : "Maker Logo "} </label> <span className="Label__Required">*</span>
                        {isEditable && (
                            <>
                                {" "}
                                <div className="file-input-container " onClick={handleCompanyIcon}>
                                    <input type="file" accept="image/*" style={{ display: "none" }} ref={companyImageRef} onChange={handleCompanyImageChange} disabled={!isEditable} />
                                    <input className={classNames({ "p-invalid": isFormFieldValid("logo") }, "custom-file p-inputtext p-component Input__Round")} type="text" placeholder="Choose a file..." value={formik.values.logo} readOnly />
                                    <span className="file-upload-icon">
                                        <i className="pi pi-paperclip" />
                                    </span>
                                </div>
                            </>
                        )}
                        {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage("logo")}
                        {formik?.values?.logo && (
                            <div style={{ position: "relative", width: "180px", height: "180px", margin: "auto", marginTop: "15px" }}>
                                {isEditable ? <i className="pi pi-times closingIcon" onClick={() => handleImageReset()} style={{ cursor: "pointer", position: "absolute", top: "0", right: "0" }} disabled={!isEditable} /> : ""}
                                <img
                                    src={companylogoLink}
                                    alt="Maker's Logo"
                                    style={{ maxWidth: "100%", maxHeight: "100%", width: "150px", height: "150px", objectFit: "contain" }}
                                    onClick={() => {
                                        previewImage();
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        {isEditable === true && <Button className="Save-Button ml-2" label="Update" type="submit" loading={loadingBtn} />}
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditViewCompany;
