import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

const Paginator = ({ setRecordCount, setCurrentPage, setTotalPageCount, recordCount, currentPage, totalPageCount, quotationsCount }) => {

    const [pageNumbers, setPageNumbers] = useState([]);
    const countOptions = [{ id: 10 }, { id: 15 }, { id: 25 }];
    const displayCount = 4;

    const startIndex = (currentPage - 1) * recordCount + 1;
    const endIndex = Math.min(currentPage * recordCount, quotationsCount);

    const handlePrevious = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNext = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPageCount));
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        setTotalPageCount(Math.ceil(quotationsCount / recordCount));
    }, [quotationsCount, recordCount]);

    useEffect(() => {
        const startPage = Math.max(1, currentPage - Math.floor(displayCount / 2));
        const endPage = Math.min(totalPageCount, startPage + displayCount - 1);
        const newPageNumbers = [];

        for (let i = startPage; i <= endPage; i++) {
            newPageNumbers.push(i);
        }

        setPageNumbers(newPageNumbers);
    }, [currentPage, totalPageCount]);

    return (
        <div className="customPaginator dark-bg mt-2 p-3 flex justify-content-center align-items-center resp-column-paginator" style={{ overflowX: "auto", overflowY: "auto" }}>
            <p className="text-black mb-0">{`Showing ${startIndex} to ${endIndex} of ${quotationsCount} records`}</p>
            <div className="flex justify-content-center align-items-center mx-4">
                <Button
                    className={`mx-1 paginatorPages p-button-secondary bg-transparent`}
                    label=""
                    icon={"pi pi-angle-double-left"}
                    onClick={() => {
                        setCurrentPage(1);
                    }}
                    disabled={currentPage === 1}
                />
                <Button className={`mx-1 paginatorPages p-button-secondary bg-transparent`} label="" icon={"pi pi-angle-left"} onClick={handlePrevious} disabled={currentPage === 1} />
                <div className="flex justify-content-center mx-2">
                    {pageNumbers?.map((pageNumber) => (
                        <Button key={pageNumber} label={pageNumber?.toString()} onClick={() => handlePageClick(pageNumber)} className={`mx-1 paginatorPages ${currentPage === pageNumber ? "p-button-primary text-white" : "p-button-secondary bg-transparent"}`} />
                    ))}
                </div>
                <Button className={`mx-1 paginatorPages p-button-secondary bg-transparent`} label="" icon={"pi pi-angle-right"} onClick={handleNext} disabled={currentPage === totalPageCount} />
                <Button
                    className={`mx-1 paginatorPages p-button-secondary bg-transparent`}
                    label=""
                    icon={"pi pi-angle-double-right"}
                    onClick={() => {
                        setCurrentPage(totalPageCount);
                    }}
                    disabled={currentPage === totalPageCount}
                />
            </div>
            <Dropdown
                options={countOptions}
                optionLabel="id"
                optionValue="id"
                value={recordCount}
                onChange={(e) => {
                    setRecordCount(e.target.value);
                    setCurrentPage(1);
                }}
            />
        </div>
    );
};

export default Paginator;
