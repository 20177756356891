import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";

// axios.interceptors(() => {});

const handleRefreshToken = async () => {
    try {
        const loginData = JSON.parse(localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails"));
        let refreshToken = loginData?.data?.refreshToken;

        const response = await axios({
            method: "post",
            url: `${baseURL}/api/v1/auth/refreshToken`,
            headers: {
                authorization: `Bearer ${refreshToken}`,
            },
        });
        let apiAccessToken = response?.data?.data?.accessToken;

        loginData.data.accessToken = apiAccessToken;

        localStorage.getItem("rememberMe") === "y" ? localStorage.setItem("userDetails", JSON.stringify(loginData)) : sessionStorage.setItem("userDetails", JSON.stringify(loginData));

        return apiAccessToken;
    } catch (error) {
        return null;
    }
};

export const handleGetRequest =
    (url, isShowLoad = false) =>
    async (dispatch) => {
        try {
            const loginData = JSON.parse(localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails"));
            let accessToken = loginData?.data?.accessToken;

            const response = await axios.get(`${baseURL + url}`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            });

            return response.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                const newToken = await handleRefreshToken();
                if (newToken) {
                    return await handleGetRequest(url, isShowLoad, true)(dispatch);
                } else {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.href = "/";
                    toast.error("Session expired. Please log in again.");
                    return;
                }
            }

            const id = toast.loading("Please wait...");
            if (error?.response?.status === 500) {
                toast.update(id, { render: error?.response?.data?.message || "Something went wrong !!", type: "error", isLoading: false, autoClose: 3000 });
            } else {
                toast.update(id, { render: error?.response?.data?.message || "Something went wrong !!", type: "warn", isLoading: false, autoClose: 3000 });
            }
        }
    };
