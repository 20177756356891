import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import editIcon from "../../../../assets/png/edit.png";
import deleteIcon from "../../../../assets/png/delete.png";
import Modal from "../../../../components/modal/Modal";
import viewIcon from "../../../../assets/png/view.png";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { handleDeleteRequest } from "../../../../services/DeleteTemplate";
import Loading from "../../../../components/Loader";
import AddCategory from "./AddCategory";
import EditViewCategory from "./EditViewCategory";
import { useHistory } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { handlePutRequest } from "../../../../services/PutTemplate";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import NavigationComponent from "../../../../components/NavigationComponent";
import { Tooltip } from "primereact/tooltip";

const ManageCatagory = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [categorydataId, setCategoryDataId] = useState();
    const [displayDialog, setDisplayDialog] = useState(false);
    const [allCategory, setAllCategory] = useState([]);
    const [isEditable, setIsEditAble] = useState(false);
    const [currentModal, setCurrentModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        updatedStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [switchModal, setSwitchModal] = useState(false);
    const [switchData, setSwitchData] = useState(null);
    const [statusValue, setStatusValue] = useState("all");
    const [filteredCategories, setFilteredCategories] = useState([]);
    const statusOptions = [
        { id: "all", name: "All" },
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" },
    ];
    const dispatch = useDispatch();
    const history = useHistory();
    const userIdStr = localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails");

    const userId = JSON.parse(userIdStr);
    const userRole = userId?.data?.role;

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value.replace(/^\s+/, ""));
    };

    const statusTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                <InputSwitch
                    className="edit-btn ml-1 mt-2"
                    checked={rowData.isActive == true}
                    onChange={() => {
                        handleSwitchModalOpen();
                        setSwitchData(rowData);
                    }}
                />
            </div>
        );
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center">
                {/* {!userRole?.toLowerCase().includes("user") && (
                    <InputSwitch
                        className="edit-btn ml-3 mt-2"
                        checked={rowData.isActive == true}
                        onChange={() => {
                            handleSwitchModalOpen();
                            setSwitchData(rowData);
                        }}
                    />
                )} */}
                {!userRole?.toLowerCase().includes("user") && (
                    <Button className="edit-btn ml-1 px-2" onClick={() => handleEditCategory(rowData)}>
                        <img src={editIcon} alt="Edit" />
                    </Button>
                )}
                <Button className="edit-btn ml-1 px-2" onClick={() => handleViewCategory(rowData)}>
                    <img src={viewIcon} alt="View" />
                </Button>
                {!userRole?.toLowerCase().includes("user") && (
                    <Button
                        className="edit-btn ml-1 px-2"
                        onClick={() => {
                            handleOpenModal();
                            setModalData(rowData);
                        }}
                    >
                        <img src={deleteIcon} alt="Delete" />
                    </Button>
                )}
            </div>
        );
    };
    const handleInputSwitch = async () => {
        setLoadingBtn(true);

        let obj = {
            isActive: switchData?.isActive ? false : true,
        };

        const response = await dispatch(handlePutRequest(obj, `/api/v1/category/update/${switchData?.id}`, false, true));

        if (response?.status === 200) {
            getCategoryList();
        }
        setLoadingBtn(false);
        handleSwitchModalClose();
    };
    const handleSwitchModalOpen = () => {
        setSwitchModal(true);
    };
    const handleSwitchModalClose = () => {
        setSwitchModal(false);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const onHide = () => {
        setDisplayDialog(false);
        setCurrentModal(null);
    };

    const handleDialog = () => {
        setCurrentModal("Add");
        setDisplayDialog(true);
    };

    const handleEditCategory = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditAble(true);
        setCategoryDataId(rowData.id);
    };
    const handleViewCategory = (rowData) => {
        setCurrentModal("EditView");
        setDisplayDialog(true);
        setIsEditAble(false);
        setCategoryDataId(rowData.id);
    };

    const getCategoryList = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest("/api/v1/category/getAllP", false));
        if (response) {
            setAllCategory(response?.data);
        }

        setLoading(false);
    };

    useEffect(() => {
        getCategoryList();
    }, []);
    useEffect(() => {
        const updatedResponse = allCategory?.filter((item) => {
            if (statusValue === "active") return item?.isActive;
            else if (statusValue === "inactive") return !item?.isActive;
            else return item;
        });
        setFilteredCategories(updatedResponse);
    }, [statusValue, allCategory]);

    const handleDeleteCategory = async () => {
        setLoadingBtn(true);

        const response = await dispatch(handleDeleteRequest(`/api/v1/category/delete/${modalData?.id}`, true, true));
        if (response?.status === 200) {
            getCategoryList();
        }
        setLoadingBtn(false);
        handleCloseModal();
    };
    const optionTemplate = (option) => {
        return <div className={option.className}>{option.name}</div>;
    };
    const selectedItemTemplate = (option) => {
        if (option) {
            return <div className={option.className}>{option.name}</div>;
        }
        return <span>Select Status</span>;
    };
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");

        let ampm = "AM";
        if (hours >= 12) {
            ampm = "PM";
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const descriptionTemplate = (rowData) => {
        const queryText = rowData?.description ? rowData?.description : "";
        const truncatedText = queryText?.length <= 30 ? queryText : queryText?.substring(0, 30) + "...";

        return (
            <span data-pr-tooltip={queryText} style={{ cursor: "pointer" }}>
                {truncatedText}
            </span>
        );
    };

    const headerText = currentModal === "Add" ? "Add Category" : isEditable === true ? "Edit Category" : "View Category";
    const header = <span className="title-border">{headerText}</span>;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog header={header} visible={displayDialog} onHide={() => onHide()} style={{ width: "40vw" }}>
                        {currentModal === "Add" ? <AddCategory onHide={onHide} getCategoryList={getCategoryList} /> : currentModal === "EditView" && <EditViewCategory onHide={onHide} categorydataId={categorydataId} getCategoryList={getCategoryList} isEditable={isEditable} />}
                    </Dialog>
                    <div>
                        <Modal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleDeleteCategory} rowData={modalData} loadingBtn={loadingBtn} type="delete" headerText="Are you sure you want to delete this Category?" />
                    </div>{" "}
                    <div>
                        <Modal
                            isOpen={switchModal}
                            onClose={handleSwitchModalClose}
                            onConfirm={handleInputSwitch}
                            type="active/inactive"
                            loadingBtn={loadingBtn}
                            rowData={switchData}
                            isActive={switchData?.isActive}
                            headerText={`Are you sure you want to ${switchData?.isActive ? "Inactive" : "Active"} this Category?`}
                        />
                    </div>
                    <NavigationComponent title={`Category Management`} addNewHandleDialog={handleDialog} isBackButtonLink={() => history.goBack()} />
                    <div className="card-ml-1 dark-bg">
                        <DataTable
                            header={
                                <div className="flex justify-content-end custom-alignment" style={{ display: "flex", alignItems: "center" }}>
                                    <span className="mr-2 font-semibold text-base">Status:</span>

                                    <Dropdown
                                        className=""
                                        style={{ width: "215px" }}
                                        options={statusOptions}
                                        optionLabel="name"
                                        optionValue="id"
                                        value={statusValue}
                                        onChange={(e) => setStatusValue(e.target.value)}
                                        placeholder="Select Status"
                                        itemTemplate={optionTemplate}
                                        valueTemplate={selectedItemTemplate}
                                    />
                                    <span className="p-input-icon-right">
                                        <i className="pi pi-search" />
                                        <InputText className="Input__Round ml-3" placeholder="Search..." value={globalFilterValue} onChange={onGlobalFilterChange} />
                                    </span>
                                </div>
                            }
                            filters={filters}
                            globalFilterFields={["name", "description"]}
                            responsive={true}
                            responsiveLayout="scroll"
                            stripedRows
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 20, 50]}
                            value={filteredCategories}
                            emptyMessage="No records found"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        >
                            <Column field="name" header="Category" style={{ width: "150px" }} />
                            <Column body={descriptionTemplate} header="Description" style={{ width: "150px" }} />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created On" style={{ width: "150px" }} />
                            {!userRole?.toLowerCase().includes("user") && <Column header="Status" body={statusTemplate} style={{ width: "90px" }} />}
                            <Column header="Action" body={actionTemplate} style={{ width: "100px" }} />
                        </DataTable>
                        <Tooltip target="[data-pr-tooltip]" />

                        <Modal />
                    </div>
                </>
            )}
        </>
    );
};

export default ManageCatagory;
