import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handlePutRequest } from "../../../services/PutTemplate";
import Loading from "../../../components/Loader";
import classNames from "classnames";
import SecuredFileChange from "../../../components/SecuredFileChange/SecuredFileChange";
import { baseURL } from "../../../Config";
import { Dialog } from "primereact";
import { Carousel } from "primereact/carousel";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";

const EditViewParts = ({ onHide, getPartList, partsdataId, isEditable }) => {

    const [dimension, setDimension] = useState([]);
    const [variant, setVariant] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFileArray, setSelectedFileArray] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [displayImageDialog, setDisplayImageDialog] = useState(false);
    const [variants, setVariants] = useState([]);
    const [shippingData, setShippingData] = useState([]);
    const [brandData, setBrandData] = useState([]);

    const partImageRef = useRef(null);
    const dispatch = useDispatch();

    const getPartById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/parts/getPartDetailsOnId/${partsdataId}`, false));
        if (response?.status === 200) {
            const keyData = response?.data[0];
            formik.setFieldValue("dimensionsId", keyData?.partDimension);
            formik.setFieldValue("variantId", keyData?.variantId);
            formik.setFieldValue("sku_no", keyData?.sku_no);
            formik.setFieldValue("quantity", keyData?.quantity ? keyData?.quantity : "");
            formik.setFieldValue("cost", keyData?.partVariantCost);
            formik.setFieldValue("currency", keyData?.currencyCode);
            formik.setFieldValue("part_no", keyData?.part_no);
            formik.setFieldValue("part_name", keyData?.part_name);
            formik.setFieldValue("makerId", keyData?.makerId);
            formik.setFieldValue("shippingId", keyData?.shippingMethodId);
            formik.setFieldValue("brandId", keyData?.partBrand);
            formik.setFieldValue("condition", keyData?.partCondition);
            formik.setFieldValue("description", keyData?.part_description);
            formik.setFieldValue("isActive", keyData?.partIsActive ? true : false);
            setSelectedFileArray(
                keyData?.images?.map((item) => {
                    return `${baseURL}/${item?.img_path}`;
                }) ?? []
            );
        }
        setLoading(false);
    };

    useEffect(() => {
        getPartById();
    }, [partsdataId]);

    const validationSchema = Yup.object().shape({
        sku_no: Yup.string()
            .matches(/^[a-zA-Z0-9\s]*$/, "Sku number must be alpha numeric")
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),

        part_no: Yup.string()
            .required("This field is required")
            .matches(/^[a-zA-Z0-9\s]*$/, "Part number must be alpha numeric")
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed"),
        cost: Yup.number().nullable(),
        currency: Yup.string().when("cost", {
            is: (cost) => cost !== undefined && cost !== null && cost !== "",
            then: Yup.string().required("Please select currency"),
            otherwise: Yup.string(),
        }),
        quantity: Yup.string()?.required("Quantity should be atleast 1"),
        shippingId: Yup.mixed()?.required("Shipping is required"),
        brandId: Yup.mixed()?.required("Brand is required"),
        condition: Yup.mixed()?.required("Condition is required"),
        part_name: Yup.string()?.required("This field is required"),
        images: Yup.string().when([], {
            is: () => selectedFileArray.length === 0,
            then: Yup.string().required("You must upload at least one image"),
            otherwise: Yup.string(),
        }),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            sku_no: "",
            part_no: "",
            part_name: "",
            quantity: "",
            shippingId: "",
            brandId: "",
            description: "",
            condition: "",
            dimensionsId: "",
            variantId: "",
            cost: "",
            currency: "",
            images: "",
        },

        onSubmit: async (data) => {
            setLoading(true);
            setLoadingBtn(true);

            const newBase64Array = selectedFileArray.filter((item) => item.startsWith("data:image"));
            const updatedSelectedFileArray = selectedFileArray.filter((item) => !item.startsWith("data:image")).map((url) => url.replace(`${baseURL}/`, ""));
            let obj = {
                part_no: data?.part_no,
                sku_no: data?.sku_no,
                part_name: data?.part_name,
                partId: data?.partId,
                dimensionsId: data?.dimensionsId ? data?.dimensionsId : '',
                quantity: parseInt(data?.quantity),
                condition: data?.condition,
                shippingId: data?.shippingId,
                brand: data?.brandId,
                description: data?.description,
                cost: data?.cost ? data?.cost : undefined,
                variantId: data?.variantId ? data?.variantId : undefined,
                currencyCode: data?.currency ? data?.currency?.toString() : undefined,
                newImages: newBase64Array ? newBase64Array : null,
                oldImages: updatedSelectedFileArray ? updatedSelectedFileArray : null,
            };

            const response = await dispatch(handlePutRequest(obj, `/api/v1/parts/update/${partsdataId}`, false, true));
            if (response?.status == 200) {
                await getPartList();
                onHide();
            }
            setLoading(false);
            setLoadingBtn(false);
        },
    });

    const getDimension = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/dimension/getAll", false));
        if (response) {
            const filteredDimension = response?.data?.filter((item) => {
                return item?.isActive === true;
            });
            setDimension(filteredDimension);
        }
    };

    const getVariant = async (variantId) => {
        const response = await dispatch(handleGetRequest("/api/v1/variant/getAll", false));
        if (response) {
            const filteredVariant = response?.data?.variant?.filter((item) => {
                return item?.variantIsActive == "1";
            });
            setVariant(filteredVariant);
            if (variantId) { // assuming variantIds is an array of IDs
                const matchingVariants = filteredVariant?.filter(item => variantId.includes(item?.variantId));
                // Update state with an array of objects matching the condition
                setVariants(matchingVariants.map(variant => ({
                    variantName: variant?.variantName,
                    variantModelName: variant?.variantModelName,
                    variantYear: variant?.variantYear
                })));
            }
        }
    };

    const getCurrencies = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/currencies/getAll", false));
        if (response?.status == 200) {
            setCurrencies(
                response?.data?.map(currency => ({
                    value: currency.code,
                    label: `${currency?.name} - ${currency?.code}`
                }))
            );
        }
    };

    const getShipping = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/shipping/getAll", false));
        if (response?.status == 200) {
            setShippingData(response?.data);
        }
    };

    const getBrand = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/brands/getAll", false));
        if (response?.status == 200) {
            setBrandData(response?.data);
        }
    };

    useEffect(() => {
        getDimension();
        getVariant();
        getCurrencies();
        getShipping();
        getBrand();
    }, []);

    const previewImage = () => {
        setDisplayImageDialog(true);
    };
    const closeDisplayDialog = () => {
        setDisplayImageDialog(false);
    };

    const handlePartIcon = () => {
        partImageRef.current.click();
    };

    const handleDeleteImage = (index) => {
        setSelectedFileArray((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 3,
            numScroll: 3,
        },
    ];

    const imagePreviewer = (file) => {
        return (
            <div className="p-field col-12 md:col-12">
                <img src={file} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
            </div>
        );
    };

    function handlePartImageChange(event) {
        setSelectedFileError("");
        if (selectedFileArray?.length >= 6) {
            setSelectedFileError("You can only upload a maximum of 6 images.");
            return;
        }
        const selectedFile = event.target.files[0];
        const fileTypes = ["image"];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFileArray((prevItems) => [...prevItems, base64String]);
            };
            reader.readAsDataURL(newFile);
        }
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (formik?.values?.variantId) {
            getVariant(formik?.values?.variantId)
        }
    }, [formik?.values?.variantId])
    console.log('selectedFileArray',selectedFileArray)

    return (
        <>
            <Dialog visible={displayImageDialog} blockScroll style={{ width: "50vw" }} maskClassName="zindex9999" onHide={() => closeDisplayDialog()} draggable={false}>
                {selectedFileArray.length > 1 ? (
                    <Carousel value={selectedFileArray || []} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel" circular={false} itemTemplate={imagePreviewer} />
                ) : (
                    <img src={selectedFileArray[0]} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
                )}
            </Dialog>

            {loading ? <Loading /> : null}

            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Part Name </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("part_name") }, "Input__Round")} id="part_name" name="part_name" type="text" maxLength={25} value={formik.values.part_name} onChange={formik.handleChange} disabled={!isEditable} placeholder="Enter Part Name" />
                        {getFormErrorMessage("part_name")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Part Number </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("part_no") }, "Input__Round")} id="part_no" name="part_no" type="text" maxLength={20} value={formik.values.part_no} onChange={formik.handleChange} disabled={!isEditable} placeholder="Enter Part Number" />
                        {getFormErrorMessage("part_no")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> SKU Number </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("sku_no") }, "Input__Round")} id="sku_no" name="sku_no" type="text" maxLength={25} value={formik.values.sku_no} onChange={formik.handleChange} disabled={!isEditable} placeholder="Enter SKU Number" />
                        {getFormErrorMessage("sku_no")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Quantity </label>
                        <span className="Label__Required">*</span>
                        <InputText className={classNames({ "p-invalid": isFormFieldValid("quantity") }, "Input__Round")} id="quantity" name="quantity" type="text" maxLength={7} value={formik.values.quantity} onChange={formik.handleChange} disabled={!isEditable} placeholder="Enter Quantity" />
                        {getFormErrorMessage("quantity")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Variant </label>
                        <span className="Label__Required"></span>
                        <MultiSelect
                            className={classNames({ "p-invalid": isFormFieldValid("variantId") }, "Dropdown__Round")}
                            id="variantId"
                            name="variantId"
                            options={variant}
                            optionValue="variantId"
                            optionLabel="variantName"
                            filter
                            showClear={!!formik?.values?.variantId}
                            filterBy="variantName"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("variantId", e.target.value ? e.target.value : "");
                            }}
                            value={formik.values.variantId}
                            disabled={!isEditable}
                            placeholder="Select Variant"
                        />
                        {getFormErrorMessage("variantId")}
                    </div>

                    {formik?.values?.variantId ? (
                        <div className="field col-12 md:col-12">
                            {variants.map((variant, index) => (
                                <div
                                    key={index}
                                    className="flex"
                                    style={{
                                        marginBottom: "1rem", // Add spacing between rows
                                        // alignItems: "flex-start", // Align items for better spacing
                                    }}
                                >
                                    <div className="field col-12 md:col-6 ">
                                        <label>{variant?.variantName} Variant Year:</label>
                                        <InputText
                                            className="Input__Round"
                                            disabled
                                            id={`variantYear-${index}`}
                                            name={`variantYear-${index}`}
                                            type="text"
                                            value={variant?.variantYear || ""}
                                            onChange={(e) => {
                                                const updatedVariants = [...variants];
                                                updatedVariants[index].variantYear = e.target.value;
                                                setVariants(updatedVariants);
                                            }}
                                            placeholder="Enter Variant Year"
                                        />
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label>{variant?.variantName} Variant Model:</label>
                                        <InputText
                                            className="Input__Round"
                                            disabled
                                            id={`variantModel-${index}`}
                                            name={`variantModel-${index}`}
                                            type="text"
                                            value={variant.variantModelName || ""}
                                            onChange={(e) => {
                                                const updatedVariants = [...variants];
                                                updatedVariants[index].variantModelName = e.target.value;
                                                setVariants(updatedVariants);
                                            }}
                                            placeholder="Enter Variant Model"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}

                    <div className="field col-12 md:col-6">
                        <label> Dimensions </label>
                        <span className="Label__Required"></span>
                        <Dropdown
                            className={classNames({ "p-invalid": isFormFieldValid("dimensionsId") }, "Dropdown__Round")}
                            id="dimensionsId"
                            name="dimensionsId"
                            options={dimension}
                            optionValue="dimension"
                            optionLabel="dimension"
                            filter
                            showClear={!!formik?.values?.dimensionsId}
                            filterBy="dimension"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("dimensionsId", e.target.value ? e.target.value : "");
                            }}
                            value={formik.values.dimensionsId}
                            disabled={!isEditable}
                            editable
                            maxLength={30}
                            placeholder="Enter or Select"
                        />
                        {getFormErrorMessage("dimensionsId")}
                    </div>
                    <div className="flex field col-12 md:col-6">
                        <div className="field col-12 md:col-6 ">
                            <label> Price </label>
                            <span className="Label__Required"></span>
                            <InputText
                                keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                className={classNames({ "p-invalid": isFormFieldValid("cost") }, "Input__Round")}
                                id="cost"
                                maxLength={8}
                                name="cost"
                                type="text"
                                value={formik.values.cost}
                                onChange={formik.handleChange}
                                placeholder="Enter Price"
                                disabled={!isEditable}
                            />
                            {getFormErrorMessage("cost")}
                        </div>
                        <div className="field col-12 md:col-6 ml-0">
                            <label> Currency </label>
                            <span className="Label__Required"></span>
                            <Dropdown
                                placeholder="Select Currency"
                                className={classNames({ "p-invalid": isFormFieldValid("currency") }, "Dropdown__Round")}
                                id="currency"
                                name="currency"
                                options={currencies}
                                optionValue="code"
                                optionLabel="label"
                                value={formik.values.currency}
                                filter
                                showClear={!!formik?.values?.currency}
                                filterBy="code"
                                showFilterClear={true}
                                resetFilterOnHide={true}
                                onChange={(e) => {
                                    formik?.setFieldValue("currency", e.target.value ? e.target.value : "");
                                }}
                                disabled={!isEditable}
                            />
                            {getFormErrorMessage("currency")}
                        </div>
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Shipping </label>
                        <span className="Label__Required">*</span>
                        <MultiSelect
                            className={classNames({ "p-invalid": isFormFieldValid("shippingId") }, "Dropdown__Round")}
                            id="shippingId"
                            name="shippingId"
                            options={shippingData}
                            optionValue="id"
                            optionLabel="shippingMethod"
                            filter
                            showClear={!!formik?.values?.shippingId}
                            filterBy="shippingMethod"
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            onChange={(e) => {
                                formik?.setFieldValue("shippingId", e.target.value ? e.target.value : "");
                            }}
                            value={formik.values.shippingId}
                            editable
                            placeholder="--Select--"
                        />
                        {getFormErrorMessage("shippingId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Brand </label>
                        <span className="Label__Required">*</span>
                        <Dropdown
                            className={classNames({ "p-invalid": isFormFieldValid("brandId") }, "Dropdown__Round")}
                            id="brandId"
                            name="brandId"
                            options={brandData}
                            optionValue="brand"
                            optionLabel="brand"
                            filterBy="brand"
                            showClear={!!formik?.values?.brandId}
                            showFilterClear={true}
                            resetFilterOnHide={true}
                            editable
                            onChange={formik.handleChange}
                            value={formik.values.brandId}
                            placeholder="Enter or Select"
                        />
                        {getFormErrorMessage("brandId")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Condition </label>
                        <span className="Label__Required">*</span>
                        <InputText
                            // keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                            className={classNames({ "p-invalid": isFormFieldValid("condition") }, "Input__Round")}
                            id="condition"
                            name="condition"
                            type="text"
                            value={formik.values.condition}
                            onChange={formik.handleChange}
                            placeholder="Enter condition"
                        />
                        {getFormErrorMessage("condition")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> Description </label>
                        <InputTextarea
                            rows={4}
                            className={classNames({ "p-invalid": isFormFieldValid("description") }, "Input__Round")}
                            id="description"
                            name="description"
                            type="text"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            placeholder="Enter Description"
                        />
                    </div>

                    {isEditable && (
                        <div className="field col-12 md:col-6">
                            <label> Upload Images </label> <span className="Label__Required">*</span>
                            <div className="file-input-container " onClick={handlePartIcon}>
                                <input type="file" accept="image/*" style={{ display: "none" }} ref={partImageRef} onChange={handlePartImageChange} disabled={!isEditable || selectedFileArray?.length >= 6} />
                                <input className={classNames({ "p-invalid": isFormFieldValid("images") }, "custom-file p-inputtext p-component Input__Round")} type="text" placeholder="Choose a file..." value={formik.values.images} readOnly disabled={!isEditable || selectedFileArray?.length >= 6} />
                                <span className="file-upload-icon">
                                    <i className="pi pi-paperclip" />
                                </span>
                            </div>
                            {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage("images")}
                        </div>
                    )}

                    <div className="field col-12 md:col-12">
                        <div>
                            <label> Uploaded Images </label>
                        </div>

                        <div className=" border-solid border-900 border-2 border-round p-5 mt-2">
                            {selectedFileArray.map((item, index) => {
                                return (
                                    <div key={index} style={{ position: "relative", display: "inline-block", margin: "5px", width: "110px", height: "110px" }}>
                                        {isEditable ? <i className="pi pi-times closingIcon" onClick={() => handleDeleteImage(index)} style={{ cursor: "pointer", position: "absolute", top: "0", right: "0" }} /> : ""}
                                        <img
                                            crossOrigin="anonymous"
                                            src={item}
                                            style={{ width: "90px", height: "90px", objectFit: "contain" }}
                                            alt="Part Image"
                                            onClick={() => {
                                                previewImage();
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        {isEditable === true && <Button className="Save-Button ml-2" label="Update" type="submit" loading={loadingBtn} />}
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditViewParts;
