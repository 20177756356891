import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Chart } from "primereact/chart";
import imgIcon from "../../assets/png/car.png";
import { handleGetRequest } from "../../services/GetTemplate";
import { baseURL } from "../../Config";
import Loading from "../../components/Loader";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import NavigationComponent from "../../components/NavigationComponent";

const Dashboard = () => {
    const dispatch = useDispatch();
    const [sellingModel, setSellingModel] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartOptions, setChartOptions] = useState([]);
    const [selectedInterval, setSelectedInterval] = useState("daily");
    const [dashboard, setDashboard] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const chartRef = useRef(null);

    const getDashboardData = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest("/api/v1/inventories/dashboard", false));
        if (response) {
            setDashboard(response?.data?.[0]);
        }
        setLoading(false);
    };

    const getReportsData = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/inventories/reports", false));
        if (response) {
            setReportData(response?.data);
        }
    };

    useEffect(() => {
        getReportsData();
        getDashboardData();
    }, []);

    useEffect(() => {
        if (reportData) {
            function getWeekNumber(date) {
                const d = new Date(date);
                const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
                const daysOffset = firstDayOfMonth.getDay();
                const firstWeekStart = firstDayOfMonth.setDate(1 - daysOffset);
                const diff = d - firstWeekStart;
                return Math.floor(diff / 604800000) + 1;
            }

            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const dailyQuotations = reportData?.daily?.map((item) => parseInt(item.quotations_count, 10));
            const weeklyQuotations = reportData?.weekly?.map((item) => parseInt(item.quotations_count, 10));
            const monthlyQuotations = reportData?.monthly?.map((item) => parseInt(item.quotations_count, 10));
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue("--text-color");
            const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
            const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

            const dailyLabels = reportData?.daily?.map((item) => {
                const dailyDate = new Date(item.date_point);
                return `${dailyDate?.getDate()}/${dailyDate?.getMonth() + 1}/${dailyDate?.getFullYear()}`;
            });

            const weeklyLabels = reportData.weekly?.map((item) => {
                const weeklyDate = new Date(item.date_point);
                return `Week ${getWeekNumber(weeklyDate)} ${months[weeklyDate.getMonth()]} ${weeklyDate.getFullYear()}`;
            });

            const monthlyLabels = reportData.monthly?.map((item) => {
                const monthlyDate = new Date(item.date_point);
                return `${months[monthlyDate.getMonth()]} ${monthlyDate.getFullYear()}`;
            });

            const dailyData = {
                labels: dailyLabels,
                datasets: [
                    {
                        label: "Daily Quotations Count",
                        data: dailyQuotations,
                        fill: false,
                        borderColor: documentStyle.getPropertyValue("--blue-500"),
                        tension: 0.4,
                    },
                ],
            };

            const weeklyData = {
                labels: weeklyLabels,
                datasets: [
                    {
                        label: "Weekly Quotations Count",
                        data: weeklyQuotations,
                        fill: false,
                        borderColor: documentStyle.getPropertyValue("--blue-500"),
                        tension: 0.4,
                    },
                ],
            };

            const monthlyData = {
                labels: monthlyLabels,
                datasets: [
                    {
                        label: "Monthly Quotations Count",
                        data: monthlyQuotations,
                        fill: false,
                        borderColor: documentStyle.getPropertyValue("--blue-500"),
                        tension: 0.4,
                    },
                ],
            };

            const options = {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {
                    legend: {
                        labels: {
                            color: textColor,
                        },
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary,
                        },
                        grid: {
                            color: surfaceBorder,
                        },
                    },
                    y: {
                        ticks: {
                            color: textColorSecondary,
                        },
                        grid: {
                            color: surfaceBorder,
                        },
                        max: 100,
                    },
                },
            };

            // let data;
            let data = dailyData;

            if (selectedInterval === "daily") {
                data = dailyData;
            } else if (selectedInterval === "weekly") {
                data = weeklyData;
            } else if (selectedInterval === "monthly") {
                data = monthlyData;
            }
            setChartData(data);
            setChartOptions(options);
        }
    }, [selectedInterval, reportData]);

    const getSellingModekData = async () => {
        const response = await dispatch(handleGetRequest("/api/v1/inventories/bestSellingModel", false));
        if (response) {
            setSellingModel(response?.data);
        }
    };

    useEffect(() => {
        getSellingModekData();
    }, []);

    const DashboardStatCards = ({ totalCount, mainLabel, label1, label2, label1Stat, label2Stat, imageUrl }) => {
        return (
            <div className="flex justify-content-between" >
                <div className="stats-text">
                    <h4>{totalCount}</h4>
                    <p className="font-semibold text-lg">{mainLabel}</p>
                    <p className="text-sm mb-1">
                        {label1}: {label1Stat}
                    </p>
                    <p className="text-sm">
                        {label2}: {label2Stat}
                    </p>
                </div>
                <div className="flex align-items-center justify-content-center img-icon">
                    <img src={imageUrl} alt="" />
                </div>
            </div>
        );
    };

    const DashboardStatCards2 = ({ totalCount, mainLabel, label1, label2, label1Stat, label2Stat, label3Stat, label4Stat, label3, label4, imageUrl }) => {
        return (
            <>

                <div className="flex justify-content-between">
                    <div className="stats-stext">
                        <h4>{totalCount}</h4>
                        <p className="font-semibold text-lg">{mainLabel}</p>
                    </div>
                    <div className="flex align-items-center justify-content-center img-icon">
                        <img src={imageUrl} alt="" />
                    </div>
                </div>

                <div className="flex justify-content-between w-full">
                    <div className="flex flex-column">
                        <span className="text-sm flex flex-row mb-1">
                            {label2}: {label2Stat}
                        </span>
                        <span className="text-sm">
                            {label3}: {label3Stat}
                        </span>
                    </div>
                    <div className="flex flex-column">
                        <span className="text-sm flex flex-row mb-1">
                            {label1}: {label1Stat}
                        </span>
                        <span className="text-sm flex flex-row">
                            {label4}: {label4Stat}
                        </span>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <NavigationComponent title={`Dashboard`} />

                    <div className="p-fluid p-formgrid grid mb-5 m-0 card-ml-1 dark-bg p-4">
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="h-full surface-0 border-50 border-round p-3 card-shadow inventory-div">
                                <DashboardStatCards totalCount={dashboard?.total_inventory_count} mainLabel={"Total Inventories"} label1={"Active Inventory"} label1Stat={dashboard?.activeInventry} label2={"Inactive Inventory"} label2Stat={dashboard?.inactiveInventry} imageUrl={imgIcon} />
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="h-full surface-0 border-50 border-round p-3 card-shadow active-div">
                                <DashboardStatCards2 totalCount={dashboard?.total_queries} mainLabel={"Total Queries"} label1={"Approved"} label1Stat={dashboard?.approved_queries} label2={"Pending"} label2Stat={dashboard?.pending_queries}  label3Stat={dashboard?.onhold_queries} label4Stat={dashboard?.processed_queries} label3='On Hold' label4='Processed' imageUrl={imgIcon} />
                            </div>
                        </div>

                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="h-full surface-0 border-50 border-round p-3 card-shadow inactive-div">
                                <DashboardStatCards totalCount={dashboard?.vehicles} mainLabel={"Total Variants"} label1={"Active"} label1Stat={dashboard?.activeVehicles} label2={"Inactive"} label2Stat={dashboard?.inactiveVehicles} imageUrl={imgIcon} />
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="h-full surface-0 border-50 border-round p-3 card-shadow vehicle">
                                <DashboardStatCards totalCount={dashboard?.parts} mainLabel={"Total Parts"} label1={"Active"} label1Stat={dashboard?.activeParts} label2={"Inactive"} label2Stat={dashboard?.inactiveParts} imageUrl={imgIcon} />
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid m-0">
                        <div className="col-12 md:col-8 lg:col-8">
                            <div className="card-ml-1 dark-bg p-5">
                                <span className="important-black">
                                    <h3>
                                        <b>Reports</b>
                                    </h3>
                                </span>
                                <div className="radio-buttons text-center">
                                    <input type="radio" id="daily" className="dateStats" name="interval" value="daily" checked={selectedInterval === "daily"} onChange={() => setSelectedInterval("daily")} />
                                    <label htmlFor="daily">Daily</label>

                                    <input type="radio" id="weekly" className="dateStats" name="interval" value="weekly" checked={selectedInterval === "weekly"} onChange={() => setSelectedInterval("weekly")} />
                                    <label htmlFor="weekly">Weekly</label>

                                    <input type="radio" id="monthly" className="dateStats" name="interval" value="monthly" checked={selectedInterval === "monthly"} onChange={() => setSelectedInterval("monthly")} />
                                    <label htmlFor="monthly">Monthly</label>
                                </div>
                                {chartData && chartOptions && <Chart id="chart" ref={chartRef} type="line" data={chartData} options={chartOptions} />}
                            </div>
                        </div>

                        <div className="col-12 md:col-4 lg:col-4">
                            <div className="card-ml-1 dark-bg p-3 h-full">
                                <div className="carDetails">
                                    <h2>Hot Selling Part</h2>
                                    <div className="carImage">
                                        <img
                                            src={baseURL + "/" + sellingModel[0]?.partImgPath}
                                            alt=""
                                            style={{ width: "260px", height: "260px", borderRadius: "20%" }}
                                            onError={(e) => {
                                                e.target.onerror = null; // Prevents looping error if fallback also fails
                                                // e.target.src = `${baseURL}/images/default-car.png`; // Fallback image
                                            }}
                                        />
                                    </div>
                                    <h2>{sellingModel[0]?.partName}</h2>
                                    <p>{sellingModel[0]?.partMakerName}</p>
                                    <p>{sellingModel[0]?.variant_name}</p>
                                </div>
                                <div className="carDescription">
                                    <ul>
                                        <li className="my-2">
                                            <b>Part Name: </b>
                                            {sellingModel[0]?.partName}{" "}
                                        </li>
                                        <li className="my-2">
                                            <b>Maker: </b>
                                            {sellingModel[0]?.partMakerName}{" "}
                                        </li>
                                        <li className="my-2">
                                            <b>Model: </b>
                                            {sellingModel[0]?.partVariantModelName}
                                        </li>
                                        <li className="my-2">
                                            <b>Year: </b>
                                            {sellingModel[0]?.partVariantYear}{" "}
                                        </li>
                                        <li className="my-2">
                                            <b>Variant: </b>
                                            {sellingModel[0]?.partVariantName}{" "}
                                        </li>
                                        <li className="my-2">
                                            <b>Category: </b>
                                            {sellingModel[0]?.partVariantCategoryName}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Dashboard;
