import React, { useEffect, useRef, useState } from 'react'
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import LoadingOverlay from "react-loading-overlay";
import { Triangle } from "react-loader-spinner";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { handleGetRequest } from '../../services/GetTemplate';
import Loading from '../../components/Loader';
import NavigationComponent from '../../components/NavigationComponent';
import { Tag } from 'primereact/tag';
import { Chart } from 'primereact/chart';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { handlePostRequest } from '../../services/PostTemplate';

const headersList = [
    { name: 'Client Name', value: 'clientName' },
    { name: 'Client Email', value: 'clientEmail' },
    { name: 'Client No.', value: 'clientPhone' },
    { name: 'Part Name', value: 'partName' },
    { name: 'Part Created Date', value: 'partCreatedDate' },
    { name: 'Variant Name', value: 'variantName' },
    { name: 'Variant Created Date', value: 'variantCreatedDate' },
    { name: 'Variant Currency Code', value: 'variantCurrencyCode' },
    { name: 'Variant Cost', value: 'variantCost' },
    { name: 'SKU', value: 'skuNo' },
    { name: 'Client Query', value: 'clientQuery' },
    { name: 'Status', value: 'status' }
]

const Reporting = () => {

    const history = useHistory()
    const dispatch = useDispatch()
    const reportRef = useRef()
    const [loading, setLoading] = useState(false)
    const [queryDialog, setQueryDialog] = useState(false)
    const [fromDate, setFromDate] = useState(moment().subtract(30, 'days').toDate())
    const [toDate, setToDate] = useState(moment().toDate())
    const [reportData, setReportData] = useState([])
    const [queryDetails, setQueryDetails] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [displayColumn, setDisplayColumn] = useState(
        headersList.map(item => item.value)
    );
    const [searchInput, setSearchInput] = useState('');

    const [columnVisibility, setColumnVisibility] = useState({
        clientName: true,
        clientEmail: true,
        clientPhone: true,
        partName: true,
        partCreatedDate: true,
        variantName: true,
        variantCreatedDate: true,
        variantCurrencyCode: true,
        variantCost: true,
        skuNo: true,
        clientQuery: true,
        status: true,
    });

    const exportCSV = () => {
        // Filter out only the visible columns
        const filterColumns = Object.keys(columnVisibility)?.filter(column => columnVisibility[column]);

        const visibleColumns = Object.keys(columnVisibility)?.map((column, i) => {
            if (filterColumns?.length === 0) {
                return column
            } else {
                return filterColumns[i]
            }
        }).filter(Boolean)

        const customHeaders = visibleColumns.map(column => {
            if (column == 'clientName') return 'Client Name';
            if (column == 'clientEmail') return 'Client Email';
            if (column == 'clientPhone') return 'Client No.';
            if (column == 'partName') return 'Part Name';
            if (column == 'partCreatedDate') return 'Part Created Date';
            if (column == 'variantName') return 'Variant Name';
            if (column == 'variantCreatedDate') return 'Variant Created Date';
            if (column == 'variantCurrencyCode') return 'Variant Currency Code';
            if (column == 'variantCost') return 'Variant Cost';
            if (column == 'skuNo') return 'SKU';
            if (column == 'clientQuery') return 'Client Query';
            if (column == 'status') return 'Status';
            return column;
        });

        // Get the data based on the selected rows or all rows if none are selected
        const dataToExport = selectedProduct?.length > 0 ? selectedProduct : reportData;

        // Filter the data to include only the visible columns
        const filteredData = dataToExport.map(row => {
            let filteredRow = {};
            visibleColumns.forEach(column => {
                filteredRow[column] = row[column];
            });
            return filteredRow;
        });

        // Convert filtered data to CSV format
        const csvContent = [
            // Add the header row with the visible columns
            customHeaders.join(','),
            // Add each row's data, joining values with commas
            ...filteredData.map(row => visibleColumns.map(column => row[column]).join(','))
        ].join('\n');

        // Create a Blob from the CSV content and generate a download link
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        let currentDate = new Date();

        let dateString = currentDate.toISOString().slice(0, 10);
        let filename = `Report-${dateString}.csv`;

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportPdf = () => {
        // Filter out only the visible columns
        const visibleColumns = Object.keys(columnVisibility).filter(column => columnVisibility[column]);

        // Get the data based on the selected rows or all rows if none are selected
        const dataToExport = selectedProduct.length > 0 ? selectedProduct : reportData;

        // Prepare rows for the PDF, ensuring only visible columns are included
        const rows = dataToExport.map(row => {
            return visibleColumns.map(column => row[column] || "N/A"); // Create array of cell values
        });

        // Set custom header names based on conditions
        const headers = visibleColumns.map(column => {
            if (column == 'clientName') return 'Client Name';
            if (column == 'clientEmail') return 'Client Email';
            if (column == 'clientPhone') return 'Client No.';
            if (column == 'partName') return 'Part Name';
            if (column == 'partCreatedDate') return 'Part Created Date';
            if (column == 'variantName') return 'Variant Name';
            if (column == 'variantCreatedDate') return 'Variant Created Date';
            if (column == 'variantCurrencyCode') return 'Variant Currency Code';
            if (column == 'variantCost') return 'Variant Cost';
            if (column == 'skuNo') return 'SKU';
            if (column == 'clientQuery') return 'Client Query';
            if (column == 'status') return 'Status';
            return column;
        });

        // Initialize the PDF document
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text("Report Details", 14, 10);

        // Define the margins and page width
        const pageWidth = doc.internal.pageSize.width;
        const margin = 10;
        const usableWidth = pageWidth - 2 * margin;

        // Calculate column widths based on available page width
        const columnCount = visibleColumns.length;
        const columnWidth = Math.max(usableWidth / columnCount, 15); // Ensure a minimum column width of 15mm

        // Add the table to the PDF using headers and rows
        doc.autoTable({
            head: [headers],
            body: rows,
            startY: 20,
            margin: { left: margin, top: 20, right: margin },
            styles: {
                cellPadding: 3,
                fontSize: 8, // Reduce font size to fit all columns
                halign: 'center',
                valign: 'middle',
                lineWidth: 0.5,  // Adds borders to the cells
                overflow: 'linebreak',  // Prevents text from spilling out
                minCellHeight: 10, // Minimum height to prevent text clipping
            },
            headStyles: {
                fontSize: 10,
                fontStyle: 'bold',
                halign: 'center',
                fillColor: [22, 160, 133],  // Greenish header background color
                textColor: [255, 255, 255],  // White text in header
            },
            bodyStyles: {
                fillColor: [255, 255, 255],  // White row background
            },
            alternateRowStyles: {
                fillColor: [240, 240, 240],  // Light grey for alternate rows
            },
            columnStyles: visibleColumns.reduce((acc, column, index) => {
                acc[index] = { cellWidth: columnWidth }; // Set column width dynamically based on total columns
                return acc;
            }, {}),
            theme: "striped"
        });

        // Add a timestamp to the filename
        const currentDate = new Date();
        const dateString = currentDate?.toISOString().slice(0, 10);
        const filename = `Report-${dateString}.pdf`;

        // Save the PDF
        doc.save(filename);
    };


    const handleColumnCheckboxChange = (event, column) => {
        setColumnVisibility(prevState => ({
            ...prevState,
            [column]: event.target.checked
        }));
    };

    const columnHeaderCheckbox = (columnKey, columnHeader) => {
        return (
            <div className="flex align-items-center">
                <Checkbox
                    type="checkbox"
                    id={`column-${columnKey}`}
                    checked={columnVisibility[columnKey]}
                    onChange={(e) => handleColumnCheckboxChange(e, columnKey)}
                />
                <label htmlFor={`column-${columnKey}`} className="ml-2 header-color" >{columnHeader}</label>
            </div>
        )
    };

    const QueryHandle = () => {
        setQueryDialog(true)
    }

    const closeDisplayDialog = () => {
        setQueryDialog(false)
    }
    const handleColumnChange = (e) => {
        const selectedColumns = e.value; // Columns selected in the MultiSelect
        setDisplayColumn(selectedColumns);

        // Update column visibility based on selected columns
        const newColumnVisibility = { ...columnVisibility };
        Object.keys(newColumnVisibility).forEach((key) => {
            newColumnVisibility[key] = selectedColumns.includes(key);
        });

        setColumnVisibility(newColumnVisibility);
    };

    useEffect(() => {
        if (displayColumn) {
            if (displayColumn && displayColumn.length > 0) {
                // Filter the data to include only the columns present in displayColumn
                const filteredData = reportData?.map(item => {
                    const filteredItem = {};
                    // Loop through displayColumn and add matching keys from item
                    displayColumn.forEach(col => {
                        if (item.hasOwnProperty(col)) {
                            filteredItem[col] = item[col];
                        }
                    });
                    return filteredItem;
                });
                setReportData(filteredData);
            }
        }
    }, [displayColumn])

    const ReportingHeader = () => {
        return (
            <>
                <div className='flex flex-column lg:flex-row md:flex-column align-items-start md:justify-content-between md:align-items-start'>
                    {/* First Part */}
                    <div className='col-12 md:col-10 flex flex-column sm:flex-row align-items-center sm:mt-0 mt-3'>
                        <div className='flex flex-column'>
                            <div className='flex flex-column md:flex-row'>
                                <div className="w-12rem my-2 xl:my-0 sm:mr-4">
                                    <label name="fromDate">From Date</label>
                                    <Calendar
                                        maxDate={new Date()} // Prevent selecting a date greater than today
                                        className="calendar"
                                        value={fromDate}
                                        name="fromDate"
                                        showIcon
                                        placeholder="mm/dd/yyyy"
                                        onChange={(e) => setFromDate(e?.target?.value)}
                                    />
                                </div>
                                <div className="w-12rem my-2 xl:my-0">
                                    <label name="toDate">To Date</label>
                                    <Calendar
                                        minDate={fromDate} // Prevent selecting a date earlier than fromDate
                                        maxDate={new Date()} // Prevent selecting a date greater than today
                                        className="calendar"
                                        value={toDate}
                                        name="toDate"
                                        showIcon
                                        placeholder="mm/dd/yyyy"
                                        onChange={(e) => setToDate(e?.target?.value)}
                                    />
                                </div>
                            </div>
                            <div className="mt-2 flex flex-column">
                                <label name="toDate">Display Columns</label>
                                <MultiSelect
                                    className='mt-1'
                                    filter
                                    display='chip'
                                    placeholder='-- Display Columns --'
                                    style={{ width: "80%" }}
                                    filterBy='name'
                                    value={displayColumn}
                                    onChange={handleColumnChange}
                                    optionValue='value'
                                    optionLabel='name'
                                    options={headersList}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Second Part */}
                    <div className="col-12 md:col-2 flex flex-column align-items-end mt-3">
                        <div className='flex flex-row mb-1'>
                            <Button
                                tooltip='Export as Excel'
                                tooltipOptions={{ position: "top" }}
                                className="Add__New-Button export-excel md:ml-2 ml-0 mt-2 md:mt-0"
                                icon="pi pi-file-excel"
                                disabled={reportData?.length === 0}
                                onClick={() => exportCSV()}
                            />
                            <Button
                                tooltip='Export as Pdf'
                                tooltipOptions={{ position: "top" }}
                                className="Add__New-Button export-pdf md:ml-2 ml-0 mt-2 md:mt-0"
                                icon="pi pi-file-pdf"
                                disabled={reportData?.length === 0}
                                onClick={() => exportPdf()}
                            />
                        </div>
                        <Button
                            tooltip='View Query Details'
                            label={
                                <>
                                    {'Query Counts'} <Tag className='ml-2' style={{ fontSize: "12px", padding: '2px 7px' }} severity='danger' value={queryDetails?.totalCount || 0}></Tag>
                                </>
                            }
                            disabled={queryDetails?.totalCount == 0}
                            tooltipOptions={{ position: "top" }}
                            className="Add__New-Button query-btn md:ml-2 ml-0"
                            onClick={QueryHandle}
                        />
                        <div className="search-container mt-1">
                            <InputText
                                className="input-field"
                                placeholder="Search via variant and part"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                            />
                            <button className="search-button pi pi-search" onClick={getReportData}></button>
                        </div>
                    </div>
                </div>
                <hr className='mt-4 mb-0'></hr>
            </>
        )
    }

    const params = new URLSearchParams({
        fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : null,
        toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : null,
        search: searchInput || null
    });

    const getReportData = async () => {
        setLoading(true)
        const response = await dispatch(handleGetRequest(`/api/v1/qoutation/reports?${params}`, false))
        if (response?.status == 200) {
            setReportData(response?.data?.data)
            setQueryDetails(response?.data)
            getChartData(response?.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (fromDate && toDate) {
            getReportData()
        }
    }, [fromDate, toDate])

    const getChartData = (query) => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: query?.queryCounts?.map(item => moment(item?.date).format('Do MMM, YYYY hh:mm A')),
            datasets: [
                {
                    label: 'Query Counts',
                    backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: query?.queryCounts?.map(item => parseInt(item?.count, 10))
                },
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Dialog header='Query Count Details' visible={queryDialog} blockScroll style={{ width: "50vw" }} maskClassName="zindex9999" onHide={() => closeDisplayDialog()} draggable={false}>
                        <Chart type="bar" data={chartData} options={chartOptions} style={{ height: "40vh" }} />
                    </Dialog>
                    <div>
                        <NavigationComponent title={`Reporting`} />
                        <div className="card-ml-1 dark-bg">
                            <DataTable
                                ref={reportRef}
                                responsive={true}
                                value={reportData}
                                selection={selectedProduct}
                                onSelectionChange={(e) => setSelectedProduct(e?.value)}
                                header={ReportingHeader}
                                className='report_datatable'
                                responsiveLayout="scroll"
                                stripedRows
                                paginator
                                rows={20}
                                emptyMessage="No records found"
                                paginatorClassName="custom-paginator"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                            >
                                {displayColumn?.length > 0 && (
                                    <Column className='table_checkbox' selectionMode="multiple" exportable={true} ></Column>
                                )}
                                {displayColumn?.includes('clientName') && (
                                    <Column field='clientName' header={columnHeaderCheckbox('clientName', 'Client Name')} body={(rowData) => rowData?.clientName || 'N/A'} />
                                )}
                                {displayColumn?.includes('clientEmail') && (
                                    <Column field='clientEmail' header={columnHeaderCheckbox('clientEmail', 'Client Email')} body={(rowData) => rowData?.clientEmail || 'N/A'} />
                                )}
                                {displayColumn?.includes('clientPhone') && (
                                    <Column field='clientPhone' header={columnHeaderCheckbox('clientPhone', 'Client No.')} body={(rowData) => rowData?.clientPhone || 'N/A'} />
                                )}
                                {displayColumn?.includes('partName') && (
                                    <Column field='partName' header={columnHeaderCheckbox('partName', 'Part Name')} body={(rowData) => rowData?.partName || 'N/A'} />
                                )}
                                {displayColumn?.includes('partCreatedDate') && (
                                    <Column field='partCreatedDate' header={columnHeaderCheckbox('partCreatedDate', 'Part Created Date')} body={(rowData) => moment(rowData?.partCreatedDate).format('DD-MM-YYYY') || 'N/A'} />
                                )}
                                {displayColumn?.includes('variantName') && (
                                    <Column field='variantName' header={columnHeaderCheckbox('variantName', 'Variant Name')} body={(rowData) => rowData?.variantName || 'N/A'} />
                                )}
                                {displayColumn?.includes('variantCreatedDate') && (
                                    <Column field='variantCreatedDate' header={columnHeaderCheckbox('variantCreatedDate', 'Variant Created Date')} body={(rowData) => moment(rowData?.variantCreatedDate).format('DD-MM-YYYY') || 'N/A'} />
                                )}
                                {displayColumn?.includes('variantCurrencyCode') && (
                                    <Column field='variantCurrencyCode' header={columnHeaderCheckbox('variantCurrencyCode', 'Variant Currency')} body={(rowData) => rowData?.variantCurrencyCode || 'N/A'} />
                                )}
                                {displayColumn?.includes('variantCost') && (
                                    <Column field='variantCost' header={columnHeaderCheckbox('variantCost', 'Variant Cost')} body={(rowData) => rowData?.variantCost || 'N/A'} />
                                )}
                                {displayColumn?.includes('skuNo') && (
                                    <Column field='skuNo' header={columnHeaderCheckbox('skuNo', 'SKU')} body={(rowData) => rowData?.skuNo || 'N/A'} />
                                )}
                                {displayColumn?.includes('clientQuery') && (
                                    <Column field='clientQuery' header={columnHeaderCheckbox('clientQuery', 'Client Query')} body={(rowData) => rowData?.clientQuery || 'N/A'} />
                                )}
                                {displayColumn?.includes('status') && (
                                    <Column field='status' header={columnHeaderCheckbox('status', 'Status')} body={(rowData) => rowData?.status || 'N/A'} />
                                )}
                            </DataTable>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Reporting

