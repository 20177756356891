import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
import { loadingAction } from "../redux/actions/loadingAction";

const handleRefreshToken = async () => {
    try {
        const loginData = JSON.parse(localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails"));
        let refreshToken = loginData?.data?.refreshToken;

        const response = await axios({
            method: "post",
            url: `${baseURL}/api/v1/auth/refreshToken`,
            headers: {
                authorization: `Bearer ${refreshToken}`,
            },
        });
        let apiAccessToken = response?.data?.data?.accessToken;

        loginData.data.accessToken = apiAccessToken;

        localStorage.getItem("rememberMe") === "y" ? localStorage.setItem("userDetails", JSON.stringify(loginData)) : sessionStorage.setItem("userDetails", JSON.stringify(loginData));

        return apiAccessToken;
    } catch (error) {
        return null;
    }
};

export const handlePutRequest =
    (data, url, isShowLoad = false, isShowToast = true) =>
    async (dispatch) => {
        // data = await trimData(data);
        try {
            if (isShowLoad) dispatch(loadingAction(true));
            const loginData = JSON.parse(localStorage.getItem("rememberMe") === "y" ? localStorage.getItem("userDetails") : sessionStorage.getItem("userDetails"));
            const accessToken = loginData?.data?.accessToken;
            const response = await axios({
                method: "PATCH",
                url: `${baseURL + url}`,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${accessToken}`,
                },
            });
            if (isShowToast) toast.success(response?.data?.message);
            if (isShowLoad) dispatch(loadingAction(false));
            return response?.data;
        } catch (error) {
            if (error?.response?.status === 401) {
                const newToken = await handleRefreshToken();
                if (newToken) {
                    return await handlePutRequest(data, url, (isShowLoad = false), (isShowToast = true))(dispatch);
                } else {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.href = "/";
                    toast.error("Session expired. Please log in again.");
                    return;
                }
            }
            if (isShowLoad) dispatch(loadingAction(false));
            if (error?.response?.status === 400 || error?.response?.status === 500) {
                toast.warn(error?.response?.data?.message || "Something went wrong !!");
            } else {
                toast.warn(error?.response?.data?.message || "Something went wrong !!");
            }
            return error?.response;
        }
    };
