import React, { useContext } from "react";
import { UserContext } from "./UserContext";

const ProfileData = () => {
    const { user } = useContext(UserContext);
    const displayRole = user?.role?.toLowerCase()?.includes("user") ? "User" : user?.role;

    return (
        <React.Fragment>
            {user?.image && <img src={user?.image} className="ProfileImg" alt="profile" style={{ size: "25px", borderRadius: "50%" }} />}
            <p className="mt-3">
                {/* {`${user?.name || ""},`} {displayRole || ""} */}
                {`${user?.name?.split(" ")[0] || ""},`} {displayRole || ""}
            </p>
        </React.Fragment>
    );
};

export default ProfileData;
