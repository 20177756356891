import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../../../services/PostTemplate";
import * as Yup from "yup";
import { InputTextarea } from "primereact/inputtextarea";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import classNames from "classnames";

const AddCategory = ({ onHide, getCategoryList }) => {
    const [loadingBtn, setLoadingBtn] = useState(false);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            ?.matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),
        description: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            description: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let newData = {
                name: data?.name,
                description: data?.description,
            };

            const response = await dispatch(handlePostRequest(newData, "/api/v1/category/create", false, true));
            if (response?.status === 200) {
                getCategoryList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Category Name </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Category Name" className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} id="name" name="name" maxLength={30} value={formik.values.name} onChange={formik.handleChange} type="text" />
                        {getFormErrorMessage("name")}
                    </div>
                    <div className="field col-12 md:col-12">
                        <label> Description</label>
                        <span className="Label__Required">*</span>
                        <InputTextarea
                            id="description"
                            name="description"
                            placeholder="Enter Category Description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            rows={5}
                            cols={10}
                            autoResize
                            maxLength={200}
                            className={classNames({ "p-invalid": isFormFieldValid("description") }, "Input__Round")}
                        />

                        {getFormErrorMessage("description")}
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        <Button className="Save-Button ml-2" label="Add" type="submit" loading={loadingBtn} />
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddCategory;
