import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import Loading from "../../../../components/Loader";
import classNames from "classnames";
import { handleGetRequest } from "../../../../services/GetTemplate";
import { handlePutRequest } from "../../../../services/PutTemplate";
import SecuredFileChange from "../../../../components/SecuredFileChange/SecuredFileChange";
import { baseURL } from "../../../../Config";
import { Dialog } from "primereact";

const EditViewFeature = ({ onHide, getFeatureList, featureDataId, isEditable }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileError, setSelectedFileError] = useState(null);
    const [featurelogoLink, setFeaturelogoLink] = useState("");
    const [loadingBtn, setLoadingBtn] = useState(false);
    const featureImageRef = useRef(null);
    const [displayImageDialog, setDisplayImageDialog] = useState(false);

    const getFeatureById = async () => {
        setLoading(true);
        const response = await dispatch(handleGetRequest(`/api/v1/feature/${featureDataId}`, false));
        if (response?.status === 200) {
            const keyData = response?.data;
            formik.setFieldValue("featureId", keyData?.id);
            formik.setFieldValue("text", keyData?.text);
            formik.setFieldValue("image", keyData?.image);
            setFeaturelogoLink(baseURL + "/" + keyData?.image);
        }
        setLoading(false);
    };

    useEffect(() => {
        getFeatureById();
    }, [featureDataId]);

    const validationSchema = Yup.object().shape({
        text: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, "Leading and trailing space is not allowed")
            .required("This field is required"),
        image: Yup.string().required("This field is required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            featureId: "",
            text: "",
            image: "",
        },

        onSubmit: async (data) => {
            setLoadingBtn(true);

            let obj = {
                id: data?.featureId,
                text: data?.text,
                image: selectedFile ? selectedFile : undefined,
            };
            const response = await dispatch(handlePutRequest(obj, "/api/v1/feature", false, true));
            if (response?.status === 200) {
                getFeatureList();
                onHide();
            }
            setLoadingBtn(false);
        },
    });

    const previewImage = () => {
        setDisplayImageDialog(true);
    };
    const closeDisplayDialog = () => {
        setDisplayImageDialog(false);
    };

    const handleImageReset = () => {
        formik.setFieldValue("image", "");
        setSelectedFile(null);
        setFeaturelogoLink("");
        featureImageRef.current.value = null; // Reset the file input value
    };
    const handleFeatureIcon = () => {
        featureImageRef.current.click();
    };

    function handleFeatureImageChange(event) {
        setSelectedFile(null);
        formik.setFieldValue("image", "");
        setSelectedFileError("");
        const selectedFile = event.target.files[0];
        const fileTypes = ["image"];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setSelectedFileError(error);
        } else if (newFile) {
            setFeaturelogoLink(URL.createObjectURL(selectedFile));
            formik.setFieldValue("image", newFile?.name);
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                setSelectedFile(base64String);
            };
            reader.readAsDataURL(newFile);
        }
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <Dialog visible={displayImageDialog} blockScroll style={{ width: "40vw" }} maskClassName="zindex9999" onHide={() => closeDisplayDialog()} draggable={false}>
                <img src={featurelogoLink} style={{ maxWidth: "100%", maxHeight: "100%", width: "900px", height: "500px", objectFit: "contain" }} />
            </Dialog>
            {loading ? <Loading /> : <></>}
            <form onSubmit={formik.handleSubmit}>
                <div className="p-fluid formgrid grid pl-2 pr-2">
                    <div className="field col-12 md:col-6">
                        <label> Feature Name </label>
                        <span className="Label__Required">*</span>
                        <InputText placeholder="Enter Category Name" className={classNames({ "p-invalid": isFormFieldValid("text") }, "Input__Round")} id="text" name="text" maxLength={30} value={formik.values.text} onChange={formik.handleChange} type="text" disabled={!isEditable} />
                        {getFormErrorMessage("text")}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label> {isEditable ? "Upload Image" : "Feature Image"} </label> <span className="Label__Required">*</span>
                        {isEditable && (
                            <>
                                <div className="file-input-container " onClick={handleFeatureIcon}>
                                    <input type="file" accept="image/*" style={{ display: "none" }} ref={featureImageRef} onChange={handleFeatureImageChange} disabled={!isEditable} />
                                    <input className={classNames({ "p-invalid": isFormFieldValid("image") }, "custom-file p-inputtext p-component Input__Round")} type="text" placeholder="Choose a file..." value={formik.values.image} readOnly />
                                    <span className="file-upload-icon">
                                        <i className="pi pi-paperclip" />
                                    </span>
                                </div>
                            </>
                        )}
                        {selectedFileError ? <small className="p-error">{selectedFileError}</small> : getFormErrorMessage("image")}
                        {formik?.values?.image && (
                            <div style={{ position: "relative", width: "180px", height: "180px", margin: "auto", marginTop: "15px" }}>
                                {isEditable ? <i className="pi pi-times closingIcon" onClick={() => handleImageReset()} style={{ cursor: "pointer", position: "absolute", top: "0", right: "0" }} /> : ""}
                                <img
                                    src={featurelogoLink}
                                    alt="Feature Image"
                                    style={{ maxWidth: "100%", maxHeight: "100%", width: "150px", height: "150px", objectFit: "contain" }}
                                    onClick={() => {
                                        previewImage();
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    <div className="col-12 d-flex flex-row text-center mt-4 pb-2">
                        <Button className="Cancel-Button" label="Cancel" type="button" onClick={onHide} />
                        {isEditable === true && <Button className="Save-Button ml-2" label="Update" type="submit" loading={loadingBtn} />}
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditViewFeature;
